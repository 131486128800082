import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';

import { useEffectOnce } from '../useEffectOnce';
import { IParseScriptsItem, parseScripts } from './parseScripts';

interface IUseContentParams {
  canParse: boolean;
  canRender: boolean;
  postContent: string;
  setPostContent: Dispatch<SetStateAction<string>>;
  done(): void;
}

/**
 * Вырезает скрипты из контента и вставляет их в body
 */
export const useContentScriptsNew = ({ canParse, canRender, postContent, setPostContent, done }: IUseContentParams) => {
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const [isParsed, setIsParsed] = useState(false);
  const [parsedScriptsParams, setParsedScriptsParams] = useState<IParseScriptsItem[]>([]);
  const [insertedScripts, setInsertedScripts] = useState<HTMLScriptElement[]>([]);

  /** При клиентском переходе с офера на офер удаляет скрипты из боди */
  useEffect(() => {
    if (pathname !== prevPathname) {
      insertedScripts.forEach(insertedScript => {
        document.body.removeChild(insertedScript);
      });

      setPrevPathname(pathname);
      setIsParsed(false);
      setInsertedScripts([]);
      setParsedScriptsParams([]);
    }
  }, [insertedScripts, pathname, prevPathname]);

  /** Вставляет скрипты в боди, которые вырезали из контента поста */
  useEffect(() => {
    if (!parsedScriptsParams.length || !canRender) {
      return;
    }

    parsedScriptsParams.forEach(({ src }) => {
      if (src && !document.body.querySelector(`script[src="${src}"]`)) {
        const script = document.createElement('script');

        script.src = src;
        document.body.appendChild(script);
        setInsertedScripts(values => [...values, script]);
      }
    });
  }, [canRender, parsedScriptsParams]);

  /** Парсит параметры разрешенных скриптов из контента */
  useEffect(() => {
    if (!canParse || isParsed) {
      return;
    }

    const { items, html } = parseScripts(postContent, true, { allowedHosts: ['https://ceditor.setka.io/'] });

    if (items.length) {
      setPostContent(html);
      setParsedScriptsParams(items);
    }

    setIsParsed(true);
    done();
  }, [canParse, done, isParsed, postContent, setPostContent]);

  /** При размонтировании удаляет скрипты из боди */
  useEffectOnce(() => {
    return () => {
      insertedScripts.forEach(insertedScript => {
        document.body.removeChild(insertedScript);
      });
    };
  });
};
