/* eslint-disable max-lines */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Outside, Edit16 as IconFileEdit16, useDeviceType } from '@cian/ui-kit';

import { AddCommentForm, EditCommentForm } from '../../../PostCommentForms';
import { UserCard } from '../UserCard';
import { BestAdviceLabel } from '../Labels';
import { Control } from '../Control';
import { ILikes, ILikesToggleLikedUsers, Likes } from '../Likes';
import { IconActionDelete16 } from '../../../Icons';
import { sanitizePostComment, unescapeHtml, useCollapse, useEffectOnce } from '../../../../utils';
import { POST_COMMENT_ID_PREFIX } from '../../../../constants';
import { ICommentParent, TLikedUsersListState } from '../../../../types/commentList';
import { ELikeType } from '../../../../types/likes';
import { IJournalAttributesSchema } from '../../../../repositories/journal/entities/journal/JournalAttributesSchema';
import * as s from './ListItem.css';
import { canEditDelete } from '../../../../../app/helpers/comments_helper';

export interface IListItem extends Pick<ICommentParent, 'authorObject' | 'comment'> {
  id: ICommentParent['id'];
  datePublished: ICommentParent['datePublish'];
  isLikesShown: boolean;
  isChild?: boolean;
  isBestAdvice?: ICommentParent['bestAdvice'];
  postAuthorId?: IJournalAttributesSchema['authorId'];
  likesCount?: ILikes['likesCount'];
  dislikesCount?: ILikes['dislikesCount'];
  likedUsers?: TLikedUsersListState;
  dislikedUsers?: TLikedUsersListState;
  before?: React.ReactNode;
  children?: React.ReactNode;
  isJustAdded?: ICommentParent['isJustAdded'];
  isDisabled?: boolean;
  canComment?: boolean;
  onAddComment(value: string, isChecked: boolean): Promise<boolean | void>;
  onExpandForm?(e: React.MouseEvent): boolean | void;
  onLikesClick?(type: ELikeType): Promise<boolean | void>;
  onToggleLikedUsers?({ isShown, type }: ILikesToggleLikedUsers): Promise<boolean | void>;
  onEditButtonClick?(e: React.MouseEvent): boolean | void;
  onEditComment?(value: string): Promise<boolean | void>;
  onRemoveButtonClick?(e: React.MouseEvent): Promise<boolean | void>;
  onRemoveComment?(e: React.MouseEvent): void;
  onResetIsJustAdded?(e?: React.MouseEvent): void;
}

/**
 * Элемент листинга комментариев
 */
export const ListItem = ({
  id,
  isLikesShown,
  datePublished,
  comment,
  children,
  isBestAdvice,
  isChild,
  authorObject,
  postAuthorId,
  likesCount,
  dislikesCount,
  likedUsers,
  dislikedUsers,
  isJustAdded,
  isDisabled,
  canComment,
  onAddComment,
  onExpandForm,
  onLikesClick,
  onToggleLikedUsers,
  onEditButtonClick,
  onEditComment,
  onRemoveButtonClick,
  onRemoveComment,
  onResetIsJustAdded,
}: IListItem) => {
  const addCommentFormRef = useRef<HTMLDivElement>(null);
  const editCommentFormRef = useRef<HTMLDivElement>(null);
  const answerControlRef = useRef<HTMLDivElement>(null);
  const listItemRef = useRef<HTMLDivElement>(null);
  const commentAndFootnoteRef = useRef<HTMLDivElement>(null);
  const [currentComment, setCurrentComment] = useState(comment);
  const [formValue, setFormValue] = useState('');
  const [subscriptionChecked, setSubscriptionChecked] = useState(true);
  const [isAddCommentFormDisabled, setIsAddCommentFormDisabled] = useState(false);
  const [isEditCommentFormDisabled, setIsEditCommentFormDisabled] = useState(false);
  const [isLikesDisabled, setIsLikesDisabled] = useState(false);
  const [isRemoveControlDisabled, setIsRemoveControlDisabled] = useState(false);
  const [isAllDisabled, setIsAllDisabled] = useState(false);
  const [toggleAddCommentForm, isAddCommentFormShown] = useCollapse(addCommentFormRef, { transitionDurationMs: 200 });
  const [toggleAnswerControl, isAnswerControlShown] = useCollapse(answerControlRef, {
    isDefaultOpened: !isChild,
    transitionDurationMs: 200,
  });
  const [collapseListItem] = useCollapse(listItemRef, {
    isDefaultOpened: !isJustAdded,
    transitionDurationMs: isJustAdded ? 400 : 200,
  });
  const [toggleCommentAndFootnote] = useCollapse(commentAndFootnoteRef, {
    isDefaultOpened: true,
    transitionDurationMs: 200,
  });
  const [toggleEditCommentForm] = useCollapse(editCommentFormRef, {
    transitionDurationMs: 200,
  });
  const deviceType = useDeviceType();
  const isPhone = deviceType === 'phone';
  const isAuthorsComment = (postAuthorId && postAuthorId === authorObject?.userId) || false;

  useEffect(() => {
    if (isDisabled === undefined) {
      return;
    }

    setIsAllDisabled(isDisabled);
  }, [isDisabled]);

  /**
   * Переключает видимость формы добавления комментария
   * и кнопки Ответить
   */
  const toggleAddCommentFormVisibility = useCallback(
    (isFormShown?: boolean) => {
      void toggleAddCommentForm(isFormShown !== undefined ? isFormShown : undefined);
      void toggleAnswerControl(isFormShown !== undefined ? !isFormShown : undefined);
    },
    [toggleAddCommentForm, toggleAnswerControl],
  );

  /**
   * Переключает видимость формы редактирования комментария
   * и поля комментария
   */
  const toggleEditCommentFormVisibility = useCallback(
    async (isFormShown?: boolean) => {
      return Promise.all([
        toggleEditCommentForm(isFormShown !== undefined ? isFormShown : undefined),
        toggleCommentAndFootnote(isFormShown !== undefined ? !isFormShown : undefined),
      ]);
    },
    [toggleEditCommentForm, toggleCommentAndFootnote],
  );

  /**
   * Сбрасывает форму добавления комментария
   */
  const resetAddCommentForm = useCallback(() => {
    setFormValue('');
    setSubscriptionChecked(true);
    setIsAddCommentFormDisabled(false);
    toggleAddCommentFormVisibility(false);

    setIsLikesDisabled(false);
    setIsRemoveControlDisabled(false);
    setIsAllDisabled(false);
  }, [toggleAddCommentFormVisibility]);

  /**
   * Сбрасывает форму редактирования комментария
   */
  const resetEditCommentForm = useCallback(() => {
    toggleEditCommentFormVisibility(false).then(() => {
      setIsEditCommentFormDisabled(false);
      setCurrentComment(comment);
    });
  }, [comment, toggleEditCommentFormVisibility]);

  useEffectOnce(() => {
    if (isJustAdded) {
      collapseListItem(true).then(isOpened => isOpened && onResetIsJustAdded && onResetIsJustAdded());
    }

    return () => {
      resetAddCommentForm();
    };
  });

  return (
    <div ref={listItemRef} id={`${POST_COMMENT_ID_PREFIX}-${id}`}>
      <div className={s['wrapper']}>
        <div className={s['headnote']}>
          <UserCard
            datePublished={datePublished}
            authorObject={authorObject}
            isAuthorLabelShown={(postAuthorId && postAuthorId === authorObject?.userId) || false}
          />
          {isBestAdvice && <BestAdviceLabel />}
        </div>

        <div ref={commentAndFootnoteRef}>
          <div>
            <div
              className={s['comment']}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: sanitizePostComment(unescapeHtml(comment)) }}
            />

            <div className={`${s['footnote']} ${!isAnswerControlShown && !isLikesShown ? s['_empty'] : ''}`}>
              {isLikesShown && (
                <Likes
                  likesCount={likesCount || 0}
                  dislikesCount={dislikesCount || 0}
                  isDisabled={isLikesDisabled || isAllDisabled || !canComment}
                  likedUsers={likedUsers}
                  dislikedUsers={dislikedUsers}
                  onClick={
                    onLikesClick &&
                    (async (...args) => {
                      setIsLikesDisabled(true);
                      await onLikesClick(...args);
                      setIsLikesDisabled(false);
                    })
                  }
                  onToggleLikedUsers={onToggleLikedUsers}
                />
              )}

              <div className={s['controls-wrapper']}>
                {!isChild && canComment && (
                  <Control
                    data-testid={'answer-control'}
                    isDisabled={isAllDisabled}
                    onClick={e => {
                      if (onExpandForm) {
                        return onExpandForm(e) && toggleAddCommentFormVisibility(true);
                      }

                      toggleAddCommentFormVisibility(true);
                    }}
                    ref={answerControlRef}
                  >
                    Ответить
                  </Control>
                )}

                {isAuthorsComment && canEditDelete(datePublished) && (
                  <Control
                    data-testid={'edit-control'}
                    isDisabled={isAllDisabled}
                    onClick={e => {
                      if (onEditButtonClick) {
                        return onEditButtonClick(e) && toggleEditCommentFormVisibility(true);
                      }

                      void toggleEditCommentFormVisibility(true);
                    }}
                  >
                    {isPhone ? <IconFileEdit16 /> : 'Редактировать'}
                  </Control>
                )}

                {isAuthorsComment && canEditDelete(datePublished) && (
                  <Control
                    data-testid={'remove-control'}
                    isDisabled={isRemoveControlDisabled || isAllDisabled}
                    onClick={
                      onRemoveButtonClick &&
                      onRemoveComment &&
                      (async (...args) => {
                        setIsRemoveControlDisabled(true);
                        setIsAllDisabled(true);

                        if ((await onRemoveButtonClick(...args)) && (await collapseListItem(false))) {
                          onRemoveComment(...args);
                        }

                        setIsRemoveControlDisabled(false);
                        setIsAllDisabled(false);
                      })
                    }
                  >
                    {isPhone ? <IconActionDelete16 /> : 'Удалить'}
                  </Control>
                )}
              </div>
            </div>
          </div>
        </div>

        <div ref={editCommentFormRef}>
          <div>
            <EditCommentForm
              value={currentComment}
              isDisabled={isEditCommentFormDisabled || isAllDisabled}
              onChangeValue={setCurrentComment}
              onSubmit={async (...args) => {
                if (!onEditComment) {
                  return;
                }

                setIsEditCommentFormDisabled(true);

                if (await onEditComment(...args)) {
                  resetEditCommentForm();
                }

                setIsEditCommentFormDisabled(false);
              }}
              onCancel={resetEditCommentForm}
            />
          </div>
        </div>

        {!isChild && (
          <Outside
            active={isAddCommentFormShown}
            onOutside={() => toggleAddCommentFormVisibility(false)}
            insideRefs={[]}
          >
            <div ref={addCommentFormRef}>
              <div className={s['form-wrapper']}>
                <AddCommentForm
                  isExpanded
                  value={formValue}
                  isSubscriptionChecked={subscriptionChecked}
                  isDisabled={isAddCommentFormDisabled || isAllDisabled}
                  onChangeValue={setFormValue}
                  onChangeSubscriptionChecked={setSubscriptionChecked}
                  onSubmit={(...args) => {
                    setIsAddCommentFormDisabled(true);
                    onAddComment(...args).then(resetAddCommentForm);
                  }}
                />
              </div>
            </div>
          </Outside>
        )}

        {children && <div className={s['comments']}>{children}</div>}
      </div>
    </div>
  );
};
