import React from 'react';

import * as s from './ListItem.css';

interface IListItem {
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?(e: React.MouseEvent): void;
}

/**
 * Базовый элемент списка
 */
export const ListItem = ({ children, onClick, style }: IListItem) => (
  <div className={`${s['wrapper']} ${onClick ? s['_clickable'] : ''}`} onClick={onClick} style={style}>
    {children}
  </div>
);
