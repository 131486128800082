import React from 'react';
import { Spinner, UIText2 } from '@cian/ui-kit';

import { AvatarStack } from '../../../AvatarStack';
import { numberWithDelimiter } from '../../../../utils';
import { TLikedUsersListState } from '../../../../types/commentList';
import { ERequestStatus } from '../../../../types/requestStatus';
import * as s from './LikesTooltipContent.css';

interface ILikesTooltipContent extends Partial<TLikedUsersListState> {
  onClick?(e: React.MouseEvent): void;
}

/**
 * Контент с пользователями для тултипа у лайков
 */
export const LikesTooltipContent = ({ status, items, onClick }: ILikesTooltipContent) => {
  return (
    <div className={s['wrapper']}>
      {status === ERequestStatus.Loading && <Spinner size={28} />}

      {status === ERequestStatus.Succeed && items?.length ? (
        <>
          <AvatarStack
            size={28}
            items={items.slice(0, 5).map(({ avatar, specialistLink }) => ({ src: avatar, href: specialistLink }))}
            marginLeft={-16}
          />

          {items.length > 5 && (
            <div className={s['more-link']} onClick={onClick}>
              <UIText2>Ещё {numberWithDelimiter(items.length - 5)}</UIText2>
            </div>
          )}
        </>
      ) : null}

      {status === ERequestStatus.Succeed && !items?.length ? <div>Не найдено</div> : null}
    </div>
  );
};
