export interface IParseJournalBannersItem {
  /** Id баннера */
  id: string;
  /** Полное HTML-тело баннера */
  outerHtml: string;
  /** Тип баннера */
  type: string;
  /** Периоды показа */
  dateRanges?: string;
  /** Тип баннера */
  position: 'top' | 'middle' | 'bottom';
}

export interface IParseJournalBanners {
  /** HTML-контент */
  html: string;
  /** Коллекция параметров для баннера */
  items: IParseJournalBannersItem[];
}

/**
 * 1. Извлекает из HTML шаблон баннера
 * 2. Обрамляет шаблон баннера div'ом
 */
export const parseJournalBanners = (
  html: string,
  replaceCallback?: (item: IParseJournalBannersItem, index: number) => string,
): IParseJournalBanners => {
  const miniBannerPattern =
    '<div[^>]*?(?:class="(minibanner)")[^>]*?>.*?<a[^>]*?(?:class="mini__button[^"]*?")[^>]*?>[^<]*?</a>[^<]*?</div>';
  const photoBannerPattern =
    '<div[^>]*?(?:class="(photo) journal_banner"[^>]*?)>.*?<a[^>]*?(?:class="photo__button[^"]*?")[^>]*?>[^<]*?</a>[^<]*?</div>[^<]*?</div>';
  const standardBannerPattern =
    '<div[^>]*?(?:class="(standard(?:-.*?)?) journal_banner")[^>]*?>.*?<img[^>]*?(?:class="standard(?:-.*?)?__img[^"]*?")[^>]*?>[^<]*?</div>[^<]*?</div>';
  const fillBannerPattern =
    '<div[^>]*?(?:class="(fill) journal_banner")[^>]*?>.*?<a[^>]*?(?:class="(fill__button|fill__link|None)[^"]*?")[^>]*?>[^<]*?</a>[^<]*?</div>';
  const pattern = new RegExp(
    `(${miniBannerPattern})|(${photoBannerPattern})|(${standardBannerPattern})|(${fillBannerPattern})`,
    'gis',
  );
  const items = [] as IParseJournalBannersItem[];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  let index = 0;
  while ((match = pattern.exec(html))) {
    index = html.indexOf(match[0], index);
    items.push({
      outerHtml: match[0],
      type: match[2] || match[4] || match[6] || match[8],
      dateRanges: match[0].match(/data-date-ranges="([^"]*?)"/)?.[1],
      id: `journal-banner-${items.length + 1}`,
      position: index / html.length < 0.333 ? 'top' : index / html.length < 0.667 ? 'middle' : 'bottom',
    });

    index++;
  }

  return {
    html: replaceCallback
      ? items.reduce((acc, item, index) => acc.replace(item.outerHtml, replaceCallback(item, index)), html)
      : html,
    items,
  };
};
