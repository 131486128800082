export interface IParsePhotoGallerySlideItem {
  /** Урл картинки */
  image: string;
  /** Копирайт картинки */
  source?: string;
  /** Описание картинки */
  description?: string;
}

export interface IParsePhotoGalleryItem {
  /** Коллекция слайдов */
  slides?: IParsePhotoGallerySlideItem[];
  /** Копирайт галереи */
  source?: string;
  /** Описание галереи */
  description?: string;
  /** Вид отображения картинки */
  objectFit?: string;
  /** Id */
  id: string;
  /** Полное HTML-тело шаблона */
  outerHtml: string;
  /** Позиция в тексте */
  position: 'top' | 'middle' | 'bottom';
}

export interface IParsePhotoGalleries {
  /** HTML-контент */
  html: string;
  /** Коллекция галерей */
  items: IParsePhotoGalleryItem[];
}

/**
 * Парсит и вырезает настройки для фотогалерей
 */
export const parsePhotoGalleriesNew = (
  html: string,
  replaceCallback?: (item: IParsePhotoGalleryItem, index: number) => string,
): IParsePhotoGalleries => {
  const pattern = /<div[^>]*?data-name="photo-gallery"[^>]*?>[^<]*?<\/div>/gis;
  const items: IParsePhotoGalleryItem[] = [];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  let index = 0;
  while ((match = pattern.exec(html))) {
    index = html.indexOf(match[0], index);
    const outerHTML = match[0];

    if (outerHTML) {
      let parsedSlides: IParsePhotoGallerySlideItem[] | null;

      try {
        parsedSlides = JSON.parse(outerHTML.match(/data-slides='([^']*?)'/)?.[1] || '');
      } catch (e) {
        parsedSlides = null;
      }

      items.push({
        slides: parsedSlides?.length ? parsedSlides : [],
        source: outerHTML.match(/data-source="([^"]*?)"/)?.[1],
        description: outerHTML.match(/data-description="([^"]*?)"/)?.[1],
        objectFit: outerHTML.match(/data-object-fit="([^"]*?)"/)?.[1],
        id: `photo-gallery-${items.length + 1}`,
        outerHtml: outerHTML,
        position: index / html.length < 0.333 ? 'top' : index / html.length < 0.667 ? 'middle' : 'bottom',
      });
    }
  }

  return {
    html: replaceCallback
      ? items.reduce((acc, item, index) => acc.replace(item.outerHtml, replaceCallback(item, index)), html)
      : html,
    items,
  };
};
