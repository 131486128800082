import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { hydrate } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { BeforeAfterGallery } from '../../components/BeforeAfterGallery';
import { parseBeforeAfterGalleries } from './parseBeforeAfterGalleries';
import { selectPostContentBeforeAfterGalleries } from '../../selectors/postContentBeforeAfterGalleries';
import {
  postContentBeforeAfterGalleryReset,
  postContentBeforeAfterGallerySet,
} from '../../actions/postContentBeforeAfterGallery';
import { TPostContentBeforeAfterGalleryState } from '../../types/postContentBeforeAfterGalleries';

interface IUseContentParams {
  canParse: boolean;
  canRender: boolean;
  postContent: string;
  setPostContent: Dispatch<SetStateAction<string>>;
  done(): void;
}

/**
 * Рендерит и управляет галереями Было/Стало
 */
export const useContentBeforeAfterGalleriesNew = ({
  canParse,
  canRender,
  postContent,
  setPostContent,
  done,
}: IUseContentParams) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const [isParsed, setIsParsed] = useState(false);
  const galleries = useSelector(selectPostContentBeforeAfterGalleries);

  /** Сбрасывает стэйт при клиентском переходе с поста на пост */
  useEffect(() => {
    if (pathname !== prevPathname) {
      dispatch(postContentBeforeAfterGalleryReset());
      setPrevPathname(pathname);
      setIsParsed(false);
    }
  }, [dispatch, pathname, prevPathname]);

  /** Рендерит галереи */
  useEffect(() => {
    if (!galleries.length || !canRender) {
      return;
    }

    galleries.forEach(({ before, after, id }: TPostContentBeforeAfterGalleryState) => {
      const root = document.getElementById(id);

      if (root && before && after) {
        hydrate(<BeforeAfterGallery imageBefore={before} imageAfter={after} />, root);
      }
    });
  }, [canRender, galleries]);

  /** Парсит параметры галерей */
  useEffect(() => {
    if (!canParse || isParsed) {
      return;
    }

    if (galleries.length) {
      setIsParsed(true);
      done();

      return;
    }

    const { items, html } = parseBeforeAfterGalleries(postContent, ({ id }) => `<div id="${id}"></div>`);

    if (items.length) {
      setPostContent(html);

      items.map(({ id, before, after, position }) =>
        dispatch(
          postContentBeforeAfterGallerySet({
            id,
            before: before || '',
            after: after || '',
            position,
          }),
        ),
      );
    }

    setIsParsed(true);
    done();
  }, [canParse, dispatch, done, galleries.length, isParsed, postContent, setPostContent]);

  /** При размонтировании сбрасывает все галереи */
  useEffect(() => {
    return () => {
      dispatch(postContentBeforeAfterGalleryReset());
    };
  }, [dispatch]);
};
