import React from 'react';

import * as s from './PostPollLayout.css';

/**
 * Декоратор для контейнера опроса в посте
 */
export const PostPollLayout = ({ children }: { children: React.ReactNode }) => (
  <div className={s['wrapper']}>{children}</div>
);
