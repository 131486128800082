import React from 'react';
import { useSelector } from 'react-redux';

import { PostLikesLayout } from '../../components/PostLikesLayout';
import { PostLikes, PostLikesSkeleton } from '../../components/PostLikes';
import { PostTelegramLink, PostTelegramLinkSkeleton } from '../../components/PostTelegramLink';
import { useLikes } from './utils';
import { selectPost } from '../../selectors/post';
import { selectUser } from '../../selectors/user';
import { PERMISSIONS } from '../../../app/permissions/permissionsNew';
import { ERequestStatus } from '../../types/requestStatus';
import { ELikeType } from '../../types/likes';
import { EType } from '../../repositories/journal/entities/journal/JournalAttributesSchema';

/**
 * Контейнер блока лайков для поста
 */
export const PostLikesContainer = () => {
  const post = useSelector(selectPost);
  const user = useSelector(selectUser);
  const { handleLikeClick, isLikesLoading } = useLikes();

  if (ERequestStatus.Loading === post.status) {
    return (
      <PostLikesLayout>
        <PostLikesSkeleton />
        <PostTelegramLinkSkeleton />
      </PostLikesLayout>
    );
  }

  if (!('id' in post)) {
    return null;
  }

  const {
    id: postId,
    attributes: { type: postType, userLikeValue },
  } = post;
  const permissions = PERMISSIONS[postType];
  const isUserLiked = Boolean(userLikeValue);

  return (
    <PostLikesLayout>
      {postType === EType.Articles && !isUserLiked && (
        <PostLikes
          isDisabled={isLikesLoading || !permissions.canLikes(user, post)}
          onLikeClick={() => handleLikeClick({ postId, postType, likeType: ELikeType.Like })}
          onDislikeClick={() => handleLikeClick({ postId, postType, likeType: ELikeType.Dislike })}
        />
      )}

      <PostTelegramLink />
    </PostLikesLayout>
  );
};
