import React, { forwardRef } from 'react';
import { Splide, SplideSlide, SplideTrack, Options, SplideEventHandlers } from '@splidejs/react-splide';
import { Image } from '@cian/ui-kit';

import { IParsePhotoGalleryItem, IParsePhotoGallerySlideItem } from '../../../../utils';
import * as s from './MainGallery.css';

export interface IMainGallery {
  slides: IParsePhotoGallerySlideItem[];
  description?: IParsePhotoGalleryItem['description'];
  objectFit?: 'cover' | 'contain';
  options?: Options;
  children?: React.ReactNode;
  onMove?: SplideEventHandlers['onMove'];
  onMounted?: SplideEventHandlers['onMounted'];
}

/**
 * Основная фотогалерея
 */
export const MainGallery = forwardRef<Splide, IMainGallery>(
  ({ slides, onMounted, onMove, description, objectFit, options, children }, ref) => {
    const defaultOptions: Options = {
      cover: true,
      heightRatio: 0.7,
      gap: 10,
      pagination: false,
      rewind: true,
      drag: true,
      keyboard: true,
    };

    return (
      <Splide
        ref={ref}
        className={s['main-gallery']}
        hasTrack={false}
        aria-label={description}
        options={{ ...defaultOptions, ...options }}
        onMounted={onMounted}
        onMove={onMove}
      >
        <SplideTrack>
          {slides.map(({ image }, index) => (
            <SplideSlide key={index} className={s['slide']} tabIndex={index}>
              <Image src={image} borderRadius={0} objectFit={objectFit} />
            </SplideSlide>
          ))}
        </SplideTrack>

        {children}
      </Splide>
    );
  },
);

MainGallery.displayName = 'MainGallery';
