import React from 'react';
import { UIText1 } from '@cian/ui-kit';

import { ListItemContent } from '../ListItemContent';
import { ListItem } from '../ListItem';
import { IPollInfoAnswerSchema } from '../../../../repositories/journal/entities/poll/PollInfoAnswerSchema';
import * as s from './StatisticsListItem.css';

interface IListItem extends IPollInfoAnswerSchema {
  pollId: number;
  isDisabled?: boolean;
  onChange?(isChecked: boolean): void;
}

/**
 * Базовый элемент списка
 */
export const StatisticsListItem = ({ image, name, percent }: IListItem) => {
  const style = {
    background: `linear-gradient(to right, var(--solid-red60) 0 ${percent || 0}%, var(--white_100) ${
      percent || 0
    }% 100%)`,
  };

  return (
    <ListItem style={style}>
      <ListItemContent image={image} name={name}>
        <div className={s['count']}>
          <UIText1 fontWeight={'bold'}>{percent}%</UIText1>
        </div>
      </ListItemContent>
    </ListItem>
  );
};
