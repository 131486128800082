import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ca } from '@cian/analytics';
import { useDeviceType } from '@cian/ui-kit';

import { useCurrentBreadCrumbs } from '../useCurrentBreadCrumbs';
import { selectUser } from '../../selectors/user';

interface IUserData {
  abGroup?: number;
  email?: string;
  type?: string;
  userId?: number;
  cianUserId?: number;
}

interface IPageData {
  breadCrumbs?: string[];
  pageType?: string;
  siteType?: 'mobile' | 'tablet' | 'desktop';
  extra?: Record<string, string | string[] | number | number[]>;
}

interface IPartnerData {
  CriteoEmail?: string;
  CriteoNewCustomer?: number;
}

interface IProductData {
  id?: number;
}

export interface IEventTrackingData {
  category?: string;
  action?: string;
  label?: string;
  user?: IUserData;
  page?: IPageData;
  partner?: IPartnerData;
  product?: IProductData;
}

/**
 * Создает HOF-функцию трекера событий для 'ca' с предзаполненными данными
 */
export const useEventTracking = (eventTrackerData?: IEventTrackingData) => {
  const { userGaData } = useSelector(selectUser);
  const breadCrumbs = useCurrentBreadCrumbs();
  const deviceType = useDeviceType();

  const getPreparedData = useCallback(
    (data: IEventTrackingData = {}): IEventTrackingData => {
      const partner = {
        ...(userGaData?.criteoEmail ? { CriteoEmail: userGaData.criteoEmail } : {}),
        ...(userGaData?.criteoNewCustomer ? { CriteoNewCustomer: userGaData.criteoNewCustomer } : {}),
        ...eventTrackerData?.partner,
        ...data?.partner,
      };
      const product = {
        ...eventTrackerData?.product,
        ...data?.product,
      };

      return {
        category: data?.category || eventTrackerData?.category,
        action: data?.action || eventTrackerData?.action,
        label: data?.label || eventTrackerData?.label,
        user: {
          abGroup: Number(userGaData?.abGroup) || 100,
          ...(userGaData?.email ? { email: userGaData.email } : {}),
          type: userGaData?.userType || 'not_authorized',
          ...(userGaData?.realtyUserId ? { userId: Number(userGaData.realtyUserId) } : {}),
          ...(userGaData?.userId ? { cianUserId: Number(userGaData.userId) } : {}),
          ...eventTrackerData?.user,
          ...data?.user,
        },
        page: {
          ...eventTrackerData?.page,
          ...data?.page,
          extra: {
            ...eventTrackerData?.page?.extra,
            ...data?.page?.extra,
          },
          breadCrumbs:
            eventTrackerData?.page?.breadCrumbs ||
            data?.page?.breadCrumbs ||
            breadCrumbs.map(breadCrumb => breadCrumb.breadCrumbName),
          pageType: 'Magazine',
          siteType: deviceType === 'phone' ? 'mobile' : deviceType,
        },
        ...(Object.keys(partner).length ? { partner } : {}),
        ...(Object.keys(product).length ? { product } : {}),
      };
    },
    [
      breadCrumbs,
      deviceType,
      eventTrackerData,
      userGaData?.abGroup,
      userGaData?.criteoEmail,
      userGaData?.criteoNewCustomer,
      userGaData?.email,
      userGaData?.realtyUserId,
      userGaData?.userId,
      userGaData?.userType,
    ],
  );

  const trackEvent = useCallback(
    (data: IEventTrackingData = {}) => {
      ca('event', {
        name: 'oldevent',
        ...getPreparedData(data),
      });
    },
    [getPreparedData],
  );

  const trackPageView = useCallback(
    (data: IEventTrackingData = {}) => {
      const { user, page, partner } = getPreparedData(data);

      ca('pageviewSite', { user, page, partner });
    },
    [getPreparedData],
  );

  return {
    trackEvent,
    trackPageView,
  };
};
