export interface IParseBeforeAfterGalleryItem {
  /** Картинка До */
  before?: string;
  /** Картинка После */
  after?: string;
  /** Id */
  id: string;
  /** Полное HTML-тело шаблона */
  outerHtml: string;
  /** Позиция в тексте */
  position: 'top' | 'middle' | 'bottom';
}

export interface IParseBeforeAfterGalleries {
  /** HTML-контент */
  html: string;
  /** Коллекция галерей */
  items: IParseBeforeAfterGalleryItem[];
}

/**
 * Парсит и вырезает настройки для галерей Было/Стало
 */
export const parseBeforeAfterGalleries = (
  html: string,
  replaceCallback?: (item: IParseBeforeAfterGalleryItem, index: number) => string,
): IParseBeforeAfterGalleries => {
  const pattern = /<div[^>]*?data-name="before-after"[^>]*?>[^<]*?<\/div>/gis;
  const items: IParseBeforeAfterGalleryItem[] = [];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  let index = 0;
  while ((match = pattern.exec(html))) {
    index = html.indexOf(match[0], index);
    const outerHTML = match[0];

    if (outerHTML) {
      items.push({
        before: outerHTML.match(/data-before="([^"]*?)"/)?.[1],
        after: outerHTML.match(/data-after="([^"]*?)"/)?.[1],
        id: `before-after-gallery-${items.length + 1}`,
        outerHtml: outerHTML,
        position: index / html.length < 0.333 ? 'top' : index / html.length < 0.667 ? 'middle' : 'bottom',
      });
    }
  }

  return {
    html: replaceCallback
      ? items.reduce((acc, item, index) => acc.replace(item.outerHtml, replaceCallback(item, index)), html)
      : html,
    items,
  };
};
