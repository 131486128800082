import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { hydrate } from 'react-dom';
import { useLocation } from 'react-router-dom';

import { PostBannerDecorator } from '../../components/PostBannerDecorator';
import { IParseJournalBannersItem, parseJournalBanners } from './parseJournalBanners';
import { parseDateRanges } from '../parseDateRanges';
import { isDateIncludedInDateRanges } from '../isDateIncludedInDateRanges';
import { useEventTracking } from '../useEventTracking';

interface IUseContentParams {
  canParse: boolean;
  canRender: boolean;
  postContent: string;
  setPostContent: Dispatch<SetStateAction<string>>;
  done(): void;
}

/**
 * Рендерит и управляет баннерами в посте
 */
export const useContentJournalBannersNew = ({
  canParse,
  canRender,
  postContent,
  setPostContent,
  done,
}: IUseContentParams) => {
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const [isParsed, setIsParsed] = useState(false);
  const [parsedParams, setParsedParams] = useState<IParseJournalBannersItem[]>([]);
  const { trackEvent } = useEventTracking();

  /** Сбрасывает стэйт при клиентском переходе с поста на пост */
  useEffect(() => {
    if (pathname !== prevPathname) {
      setPrevPathname(pathname);
      setIsParsed(false);
      setParsedParams([]);
    }
  }, [pathname, prevPathname]);

  /** Трекает клик по баннеру */
  const handleClick = useCallback(
    (url: string, position: IParseJournalBannersItem['position']) => {
      trackEvent({
        category: 'Magazine',
        action: `click_${position}_banner`,
        label: url,
      });
    },
    [trackEvent],
  );

  /** Рендерит баннеры */
  useEffect(() => {
    if (!parsedParams.length || !canRender) {
      return;
    }

    parsedParams.forEach(banner => {
      const root = document.getElementById(banner.id);

      if (root) {
        hydrate(
          <PostBannerDecorator onClick={url => handleClick(url, banner.position)}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: banner.outerHtml }} />
          </PostBannerDecorator>,
          root,
        );
      }
    });
  }, [canRender, handleClick, parsedParams]);

  /** Парсит параметры баннеров */
  useEffect(() => {
    if (!canParse || isParsed) {
      return;
    }

    const { items, html } = parseJournalBanners(postContent, ({ id, dateRanges }) => {
      const parsedDateRanges = parseDateRanges(dateRanges || '');

      /**
       * Если заданы периоды показа и ни один не актуален для текущей даты,
       * то вырезам баннер
       */
      if (parsedDateRanges?.length && !isDateIncludedInDateRanges(parsedDateRanges)) {
        return '';
      }

      return `<div id="${id}"></div>`;
    });

    if (items.length) {
      setPostContent(html);
      setParsedParams(items);
    }

    setIsParsed(true);
    done();
  }, [canParse, done, isParsed, postContent, setPostContent]);
};
