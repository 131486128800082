import { IPostContentRecommendedOffers } from '../../types/postContentRecommendedOffers';

export interface IParseRecommendedOffersItem {
  /** Id региона */
  geoId?: string;
  /** Максимальная цена */
  maxPrice?: string;
  /** Минимальная цена */
  minPrice?: string;
  /** Категории объявлений */
  offerCategories?: string[];
  /** Количество комнат */
  rooms?: string[];
  /** Заголовок */
  title?: string;
  /** Ссылка */
  url?: string;
  /** Id коллапсера */
  id: string;
  /** Полное HTML-тело шаблона adfox */
  outerHtml: string;
  /** Тип расположения баннера */
  position: IPostContentRecommendedOffers['position'];
}

export interface IParseRecommendedOffers {
  /** HTML-контент */
  html: string;
  /** Коллекция параметров коллапсера */
  items: IParseRecommendedOffersItem[];
}

/**
 * 1. Вырезает div'ы с параметрами рекомендованных оферов и извлекает данные
 * 2. Рендерит в прежнее место компонент рекомендованных оферов (рендер в строку)
 */
export const parseRecommendedOffers = (
  html: string,
  replaceCallback?: (item: IParseRecommendedOffersItem, index: number) => string,
): IParseRecommendedOffers => {
  const pattern = /<div[^>]*?class="product-slider"[^>]*?>[^<]*?<\/div>/gis;
  const items = [] as IParseRecommendedOffersItem[];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  let index = 0;
  while ((match = pattern.exec(html))) {
    index = html.indexOf(match[0], index);
    const outerHTML = match[0];

    if (outerHTML) {
      const rooms = outerHTML.match(/data-rooms="([^"]*?)"/)?.[1];
      items.push({
        geoId: outerHTML.match(/data-geo-id="([^"]*?)"/)?.[1],
        maxPrice: outerHTML.match(/data-max-price="([^"]*?)"/)?.[1],
        minPrice: outerHTML.match(/data-min-price="([^"]*?)"/)?.[1],
        offerCategories: (outerHTML.match(/data-offer-categories="([^"]*?)"/)?.[1] || '').split(','),
        rooms: rooms ? rooms.split(',') : undefined,
        title: outerHTML.match(/data-title="([^"]*?)"/)?.[1],
        url: outerHTML.match(/data-url="([^"]*?)"/)?.[1],
        id: `product-slider-${items.length + 1}`,
        outerHtml: outerHTML,
        position: index / html.length < 0.333 ? 'top' : index / html.length < 0.667 ? 'middle' : 'bottom',
      });
    }
  }

  return {
    html: replaceCallback
      ? items.reduce((acc, item, index) => {
          return acc.replace(item.outerHtml, replaceCallback(item, index));
        }, html)
      : html,
    items,
  };
};
