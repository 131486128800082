import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PostCommentsHeaderContainer } from '../PostCommentsHeader';
import { PaginationContainer } from '../Pagination';
import { PostCommentsLayout } from '../../components/PostCommentsLayout';
import { AddCommentForm, AddCommentFormSkeleton } from '../../components/PostCommentForms';
import { PostCommentList, PostCommentListSkeleton } from '../../components/PostCommentList';
import { getPostComments, resetPostComments } from '../../actions/postComments';
import { selectPost } from '../../selectors/post';
import { selectComments } from '../../selectors/postCard';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import {
  parsePostCardPath,
  useEffectOnce,
  useGetComments,
  useRemoveWrongSearchParams,
  useRequestAuthentication,
  useSearchParams,
} from '../../utils';
import {
  useAddComment,
  useEditComment,
  useLikeComment,
  useRemoveComment,
  useScrollIntoCurrentPostComment,
  useScrollIntoPostComments,
  useSetCommentParam,
} from './utils';
import { POST_CARD_COMMENTS_REQUEST_LIMIT } from '../../constants';
import { PERMISSIONS } from '../../../app/permissions/permissionsNew';
import { selectUser } from '../../selectors/user';
import { TThunkDispatch } from '../../types/redux';
import { EOrdering, ESortOrder } from '../../repositories/journal/v1/get-comments';
import { ERequestStatus } from '../../types/requestStatus';
import { EType } from '../../repositories/journal/entities/journal/JournalAttributesSchema';

export const PostCommentsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { pathname } = useLocation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { items, status: commentsStatus } = useSelector(selectComments);
  const post = useSelector(selectPost);
  const user = useSelector(selectUser);
  const { id: qsPostId } = parsePostCardPath(pathname);
  const appendType = useSelector(selectListingAppendType);
  const { page: qsPage, commentId } = useSearchParams<{ page: number; commentId: number }>(['page', 'commentId']);
  const { remove: handleRemoveButtonClick, clearStore: handleRemoveComment } = useRemoveComment();
  const handleEditComment = useEditComment();
  const handleSetCommentParam = useSetCommentParam();
  const { handleLikeClick, handleToggleLikedUsers } = useLikeComment();
  const {
    handleToggleExpanded,
    handleSubmit,
    handleUnmount,
    handleAddComment,
    handleChangeValue,
    handleChangeSubscriptionChecked,
    formValue,
    isFormExpanded,
    isFormDisabled,
    isSubscriptionChecked,
  } = useAddComment();
  const checkAuth = useRequestAuthentication();
  const isListingUpdateDisallowed = useScrollIntoCurrentPostComment();

  useScrollIntoPostComments(wrapperRef);
  useRemoveWrongSearchParams(['page']);
  useGetComments(
    useCallback(() => {
      if (isListingUpdateDisallowed) {
        return;
      }

      void dispatch(
        getPostComments({
          postId: qsPostId,
          setType: appendType,
          offset: (qsPage - 1) * POST_CARD_COMMENTS_REQUEST_LIMIT,
          limit: POST_CARD_COMMENTS_REQUEST_LIMIT,
          ordering: EOrdering.Published_at,
          sortOrder: ESortOrder.Desc,
          withCommentId: commentId || undefined,
        }),
      );
    }, [appendType, commentId, dispatch, isListingUpdateDisallowed, qsPage, qsPostId]),
    {
      dependencyNames: ['pathname', 'page', 'ordering', 'commentId'],
      scrollOptions: {
        type: commentId ? 'none' : 'ref',
        ref: wrapperRef,
      },
    },
  );

  /**
   * При размонтировании очищает форму и комменты
   */
  useEffectOnce(() => {
    return () => {
      handleUnmount();
      dispatch(resetPostComments());
    };
  });

  if (post.status === ERequestStatus.Loading) {
    return (
      <PostCommentsLayout>
        <PostCommentsHeaderContainer />
        <AddCommentFormSkeleton />
        <PostCommentListSkeleton items={[{ items: [{}, {}] }]} />
      </PostCommentsLayout>
    );
  }

  if (!('id' in post)) {
    return null;
  }

  const {
    attributes: { type, authorId },
  } = post;
  const permissions = PERMISSIONS[type];

  if (!permissions.canSeeComments(user, post)) {
    return null;
  }

  return (
    <PostCommentsLayout ref={wrapperRef}>
      <PostCommentsHeaderContainer />

      {permissions.canComment(user, post) && (
        <AddCommentForm
          value={formValue}
          isExpanded={isFormExpanded}
          isDisabled={isFormDisabled || commentsStatus === ERequestStatus.Loading}
          isSubscriptionChecked={isSubscriptionChecked}
          onSubmit={handleSubmit}
          onToggleExpanded={handleToggleExpanded}
          onChangeValue={handleChangeValue}
          onChangeSubscriptionChecked={handleChangeSubscriptionChecked}
        />
      )}

      <PostCommentList
        items={items}
        isLikesShown={type === EType.Questions}
        postAuthorId={authorId}
        isDisabled={commentsStatus === ERequestStatus.Loading}
        canComment={permissions.canComment(user, post)}
        onAddComment={handleAddComment}
        onExpandForm={checkAuth}
        onLikesClick={handleLikeClick}
        onToggleLikedUsers={handleToggleLikedUsers}
        onEditButtonClick={checkAuth}
        onEditComment={handleEditComment}
        onRemoveButtonClick={handleRemoveButtonClick}
        onRemoveComment={handleRemoveComment}
        onResetIsJustAdded={commentId => handleSetCommentParam({ id: commentId, key: 'isJustAdded' })}
      />

      <PaginationContainer />
    </PostCommentsLayout>
  );
};
