import React from 'react';

import { Details as DetailsComponent, DetailsSeparator } from '../../../Details';
import * as s from './ModerationBar.css';

interface IModerationBar {
  canPublishPost?: boolean;
  canEditPost?: boolean;
  onEditButtonClick?(e: React.MouseEvent): void;
  onPublishButtonClick?(e: React.MouseEvent): void;
}

/**
 * Компонент с кнопками для модерации поста блога
 */
export const ModerationBar = ({
  canPublishPost,
  canEditPost,
  onEditButtonClick,
  onPublishButtonClick,
}: IModerationBar) => {
  if (!canPublishPost && !canEditPost) {
    return null;
  }

  return (
    <div className={s['wrapper']}>
      <DetailsComponent size="m">
        {canEditPost && (
          <div className={s['button']} onClick={onEditButtonClick}>
            Редактировать
          </div>
        )}

        {canPublishPost && (
          <div className={s['button']} onClick={onPublishButtonClick}>
            Опубликовать
          </div>
        )}
        <DetailsSeparator />
      </DetailsComponent>
    </div>
  );
};
