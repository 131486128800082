import { createApplicationState } from '../applicationState';
import { renderApplication } from '../renderApplication';
import { createContext } from './createContext';
import '!style-loader!css-loader!../../css/fonts.css';
import '!style-loader!css-loader!../../css/colors.css';
import '!style-loader!css-loader!../../css/froala.css';
import '!style-loader!css-loader!../../css/banners.css';
import '!style-loader!css-loader!../../css/post-card-banner-standard-four.css';
import '!style-loader!css-loader!../../css/post-card-statistics.css';
import '!style-loader!css-loader!../../css/post-card-table-of-content.css';
import '!style-loader!css-loader!../../css/post-card-tables.css';
import '!style-loader!css-loader!../../css/quill.css';
import '!style-loader!css-loader!../../css/vk-video.css';
import '!style-loader!css-loader!@splidejs/react-splide/css/core';

export function runApp() {
  const context = createContext();

  const store = createApplicationState(context);

  renderApplication({ context, store });
}
