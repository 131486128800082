import * as React from 'react';
import { useSelector } from 'react-redux';
import { useDeviceType } from '@cian/ui-kit';

import { PostCardLayoutLongread, PostCardLayoutWithSidebar } from '../../components/PostCardLayouts';
import { SidebarContainer } from '../Sidebar';
import { PostCardHeaderContainer } from '../PostCardHeader';
import { PostTabsContainer } from '../PostTabs';
import { PostContainer } from '../Post';
import { PostDocumentLinkContainer } from '../PostDocumentLink';
import { PostPollContainer } from '../PostPoll';
import { PostLikesContainer } from '../PostLikes';
import { PostParentsContainer } from '../PostParents';
import { PostAdvertisingLabelContainer } from '../PostAdvertisingLabel';
import { PostStatusLabelContainer } from '../PostStatusLabel';
import { PostCommentsContainer } from '../PostComments';
import { AuthorArticlesNewContainer } from '../AuthorArticles';
import { RecommendedOffersContainer } from '../RecommendedOffers';
import { NothingFound } from '../../components/NotFound';
import { PostCardBodyLayout } from '../../components/PostCardBodyLayout';
import { PostParentsLayout } from '../../components/PostParentsLayout';
import { selectPost } from '../../selectors/post';
import { ERequestStatus } from '../../types/requestStatus';

/**
 * Контейнер карточки поста
 */
export const PostCardContainer = () => {
  const deviceType = useDeviceType();
  // TODO: пока нет механизма определения типа шаблона
  const layoutType: 'with-sidebar' | 'longread' = 'with-sidebar';
  const Layout: typeof PostCardLayoutLongread | typeof PostCardLayoutWithSidebar =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    layoutType === 'longread' ? PostCardLayoutLongread : PostCardLayoutWithSidebar;
  const post = useSelector(selectPost);

  if (ERequestStatus.Failed === post.status) {
    return <NothingFound />;
  }

  return (
    <Layout
      header={<PostCardHeaderContainer />}
      body={
        <PostCardBodyLayout>
          <PostParentsLayout>
            <PostParentsContainer />
            <PostAdvertisingLabelContainer />
            <PostStatusLabelContainer />
          </PostParentsLayout>

          <PostContainer />
          <PostDocumentLinkContainer />
          <PostPollContainer />
          <PostLikesContainer />
          <PostTabsContainer />
          <AuthorArticlesNewContainer />
          <RecommendedOffersContainer />
          <PostCommentsContainer />
        </PostCardBodyLayout>
      }
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*// @ts-ignore*/}
      {layoutType === 'with-sidebar' && deviceType === 'desktop' && <SidebarContainer />}
    </Layout>
  );
};
