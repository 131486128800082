import { IPostContentBeforeAfterGalleries } from '../../types/postContentBeforeAfterGalleries';
import {
  EPostContentBeforeAfterGalleryActionType,
  TPostContentBeforeAfterGalleryActions,
} from '../../actions/postContentBeforeAfterGallery';
import { ERequestStatus } from '../../types/requestStatus';

export const defaultState: IPostContentBeforeAfterGalleries = {
  items: [],
};

export const postContentBeforeAfterGalleriesReducer = (
  state = defaultState,
  action: TPostContentBeforeAfterGalleryActions,
) => {
  switch (action.type) {
    case EPostContentBeforeAfterGalleryActionType.Set: {
      return {
        ...state,
        items: [...state.items, { ...action.payload, status: ERequestStatus.Succeed }],
      };
    }

    case EPostContentBeforeAfterGalleryActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};
