export interface IParseScriptsItem {
  /** Полное HTML-тело по шаблону */
  body: string;
  /** Атрибут src */
  src?: string;
  /** Содержимое скрипта */
  content?: string;
}

interface IParseScripts {
  /** HTML-контент */
  html: string;
  /** Коллекция галерей */
  items: IParseScriptsItem[];
}

interface IParseScriptsOptions {
  /** Разрешенные хосты скриптов */
  allowedHosts?: string[];
}

/**
 * Парсит и опционально вырезает скрипты
 */
export const parseScripts = (
  html: string,
  remove: boolean = false,
  options: IParseScriptsOptions = {},
): IParseScripts => {
  const { allowedHosts } = options;
  const pattern = /<script.*?(?:src="(.*?)")?>(.*?)<\/script>/gis;
  const items: IParseScriptsItem[] = [];
  let match: RegExpExecArray | null;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  while ((match = pattern.exec(html))) {
    items.push({
      body: match[0],
      src: match[1],
      content: match[2],
    });
  }

  return {
    html: remove ? items.reduce((acc, item) => acc.replace(item.body, ''), html) : html,
    items: allowedHosts?.length ? items.filter(item => allowedHosts.some(host => item?.src?.startsWith(host))) : items,
  };
};
