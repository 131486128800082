import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { hydrate } from 'react-dom';

import { IParseBeforeAfterGalleryItem } from './parseBeforeAfterGalleries';
import { BeforeAfterGallery } from '../../components/BeforeAfterGallery';

interface IUseContentBeforeAfterGalleries {
  html: string;
  galleries: IParseBeforeAfterGalleryItem[];
}

/**
 * Рендерит и управляет галереями Было/Стало
 */
export const useContentBeforeAfterGalleries = ({ html, galleries }: IUseContentBeforeAfterGalleries): string => {
  /** Рендерит для CSR */
  useEffect(() => {
    galleries.forEach(({ before, after, id }: IParseBeforeAfterGalleryItem) => {
      if (before && after) {
        hydrate(<BeforeAfterGallery imageBefore={before} imageAfter={after} />, document.getElementById(id));
      }
    });
  }, [galleries]);

  /** Рендерит для SSR */
  if (typeof window === 'undefined') {
    return galleries.reduce((acc, { id, before, after }) => {
      if (!before || !after) {
        return acc;
      }

      return acc.replace(
        new RegExp(`<div id="${id}"[^>]*?></div>`),
        `<div id="${id}">${renderToString(<BeforeAfterGallery imageBefore={before} imageAfter={after} />)}</div>`,
      );
    }, html);
  }

  return html;
};
