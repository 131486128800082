import React, { FC } from 'react';
import { AdfoxContextProvider } from '../../../context/AdfoxContext';
import { PParams } from '../../../../types';
import { AdfoxBannerContainer } from '../../../../AdfoxBannerContainer';
import { TAdFoxBannerSizeProps } from '@cian/adfox-component';

const P_PARAMS: PParams = { p1: 'ddchb', p2: 'fkhj' };

const SIZE: TAdFoxBannerSizeProps = { minHeight: '244px', width: '100%' };

export const AdfoxBannerArticleFooterMobile: FC = () => {
  return (
    <AdfoxContextProvider place="articleFooter" size={SIZE} pParams={P_PARAMS}>
      {() => <AdfoxBannerContainer />}
    </AdfoxContextProvider>
  );
};
