export interface IParseBlockquotesItemUserCard {
  /** Картинка */
  image?: string;
  /** Заголовок */
  title?: string;
  /** Описание */
  description?: string;
}

export interface IParseBlockquotesItem {
  /** Текст цитаты */
  description?: string;
  /** Картинка */
  image?: string;
  /** Карточка пользователя */
  userCard?: IParseBlockquotesItemUserCard;
  /** Тема оформления */
  theme?: string;
  /** Id */
  id: string;
  /** Полное HTML-тело шаблона */
  outerHtml: string;
  /** Позиция в тексте */
  position: 'top' | 'middle' | 'bottom';
}

export interface IParseBlockquotes {
  /** HTML-контент */
  html: string;
  /** Коллекция галерей */
  items: IParseBlockquotesItem[];
}

/**
 * Парсит и вырезает настройки для цитат
 */
export const parseBlockquotes = (
  html: string,
  replaceCallback?: (item: IParseBlockquotesItem, index: number) => string,
): IParseBlockquotes => {
  const pattern = /<div[^>]*?data-name="blockquote"[^>]*?>[^<]*?<\/div>/gis;
  const items: IParseBlockquotesItem[] = [];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  let index = 0;
  while ((match = pattern.exec(html))) {
    index = html.indexOf(match[0], index);
    const outerHTML = match[0];

    if (outerHTML) {
      items.push({
        description: (outerHTML.match(/data-description="([^"]*?)"/)?.[1] || '').replace(/\\n/g, '<br/>'),
        image: outerHTML.match(/data-image="([^"]*?)"/)?.[1],
        userCard: {
          image: outerHTML.match(/data-usercard-image="([^"]*?)"/)?.[1],
          title: outerHTML.match(/data-usercard-title="([^"]*?)"/)?.[1],
          description: outerHTML.match(/data-usercard-description="([^"]*?)"/)?.[1],
        },
        theme: outerHTML.match(/data-theme="([^"]*?)"/)?.[1],
        id: `blockquote-${items.length + 1}`,
        outerHtml: outerHTML,
        position: index / html.length < 0.333 ? 'top' : index / html.length < 0.667 ? 'middle' : 'bottom',
      });
    }
  }

  return {
    html: replaceCallback
      ? items.reduce((acc, item, index) => acc.replace(item.outerHtml, replaceCallback(item, index)), html)
      : html,
    items,
  };
};
