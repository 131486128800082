import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getPostParents } from '../post';
import { selectPageMetaItems } from '../../selectors/pagesMeta';
import { selectPost } from '../../selectors/post';

/**
 * Формирует список ссылок на категории/рубрики поста
 */
export const usePostParents = () => {
  const post = useSelector(selectPost);
  const pagesMeta = useSelector(selectPageMetaItems);
  const newParents = useMemo(() => {
    if (!('id' in post)) {
      return [];
    }

    const {
      attributes: { rubrics, category, type },
    } = post;

    return getPostParents({ rubrics, category, type, pagesMeta });
  }, [pagesMeta, post]);

  const [parents, setParents] = useState(newParents);

  useEffect(() => {
    if (JSON.stringify(parents) === JSON.stringify(newParents)) {
      return;
    }

    setParents(newParents);
  }, [parents, newParents]);

  return parents;
};
