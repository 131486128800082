import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AuthorArticles, AuthorArticlesSkeleton } from '../../components/AuthorArticles';
import { selectAuthorArticles } from '../../selectors/authorArticles';
import { selectPost } from '../../selectors/post';
import { ApplicationContext, parsePostCardPath } from '../../utils';
import { getAuthorArticles, getAuthorArticlesReset } from '../../actions/authorArticles';
import { TThunkDispatch } from '../../types/redux';
import { ERequestStatus } from '../../types/requestStatus';

/** Список статей автора */
export const AuthorArticlesNewContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { items: articles, status } = useSelector(selectAuthorArticles);
  const post = useSelector(selectPost);
  const { pathname } = useLocation();
  const {
    custom: { authorArticlesLimit },
  } = useContext(ApplicationContext);
  const [prevPathname, setPrevPathname] = useState(pathname);
  const { id: qsPostId } = parsePostCardPath(pathname);

  /**
   * Сбрасывает статьи автора при переходе с поста на пост
   */
  useEffect(() => {
    if (pathname !== prevPathname) {
      dispatch(getAuthorArticlesReset());
    }
  }, [dispatch, pathname, prevPathname]);

  /**
   * Запрашивает список статей автора, если еще ни разу не запрашивали
   */
  useEffect(() => {
    if (
      (status !== ERequestStatus.Initial && pathname === prevPathname) ||
      !('id' in post) ||
      post.id !== qsPostId ||
      !authorArticlesLimit ||
      !post.attributes.rubrics?.includes(32) ||
      !post.attributes.seoTitle
    ) {
      return;
    }

    void dispatch(getAuthorArticles({ authorName: post.attributes.seoTitle, limit: authorArticlesLimit }));
    setPrevPathname(pathname);
  }, [authorArticlesLimit, dispatch, pathname, post, prevPathname, qsPostId, status]);

  /**
   * Сбрасывает статьи автора при размонтировании
   */
  useEffect(() => {
    return () => {
      dispatch(getAuthorArticlesReset());
    };
  }, [dispatch]);

  if (status === ERequestStatus.Loading) {
    return <AuthorArticlesSkeleton />;
  }

  if (!articles?.length) {
    return null;
  }

  return <AuthorArticles items={articles} />;
};
