import { PostTypeName, PostTypeUrl } from '../../constants';
import { EType, IJournalAttributesSchema } from '../../repositories/journal/entities/journal/JournalAttributesSchema';
import { IPageInfoSchema } from '../../repositories/journal/entities/page_info/PageInfoSchema';

interface IGetPostParents {
  rubrics?: IJournalAttributesSchema['rubrics'];
  category?: IJournalAttributesSchema['category'];
  type: IJournalAttributesSchema['type'];
  pagesMeta: IPageInfoSchema[];
}

export interface IPostParentItem {
  name: string;
  pathname: string;
}

/**
 * Формирует список ссылок на категории/рубрики поста
 */
export const getPostParents = ({ rubrics, category, type, pagesMeta }: IGetPostParents) => {
  const parents: IPostParentItem[] = [];

  /** Для статей формируем список ссылок на родительские рубрики */
  if (type === EType.Articles) {
    (rubrics || []).map(rubricId => {
      const pageMeta = pagesMeta.find(pageMeta => pageMeta.rubricId === rubricId);

      if (pageMeta) {
        parents.push({
          name: pageMeta.breadCrumbName,
          pathname: pageMeta.pathname,
        });
      }
    });

    return parents;
  }

  /** Корневая ссылка (Блоги, Новости, ...) */
  parents.push({
    name: PostTypeName[type],
    pathname: PostTypeUrl[type],
  });

  /** Ссылка на категорию, если есть */
  if (category) {
    const pageMeta = pagesMeta.find(pageMeta => pageMeta.category === category);

    if (pageMeta) {
      parents.push({
        name: pageMeta.breadCrumbName,
        pathname: pageMeta.pathname,
      });
    }
  }

  return parents;
};
