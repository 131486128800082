import { ITypedReduxAction } from '../../types/redux/actionType';
import { IPostContentBeforeAfterGallery } from '../../types/postContentBeforeAfterGalleries';

export enum EPostContentBeforeAfterGalleryActionType {
  Set = 'postContentBeforeAfterGalleries/set',
  Reset = 'postContentBeforeAfterGalleries/reset',
}

export type TPostContentBeforeAfterGallerySet = ITypedReduxAction<
  EPostContentBeforeAfterGalleryActionType.Set,
  IPostContentBeforeAfterGallery
>;

export type TPostContentBeforeAfterGalleryReset = ITypedReduxAction<EPostContentBeforeAfterGalleryActionType.Reset>;

export type TPostContentBeforeAfterGalleryActions =
  | TPostContentBeforeAfterGallerySet
  | TPostContentBeforeAfterGalleryReset;
