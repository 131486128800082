import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IPostPoll } from '../../../types/postPoll';

export enum EGetPostPollActionType {
  Loading = 'postPoll/getPostPoll/loading',
  Succeed = 'postPoll/getPostPoll/succeed',
  Failed = 'postPoll/getPostPoll/failed',
}

export type TGetPostPollLoading = ITypedReduxAction<EGetPostPollActionType.Loading>;
export type TGetPostPollSucceed = ITypedReduxAction<EGetPostPollActionType.Succeed, IPostPoll>;
export type TGetPostPollFailed = ITypedReduxAction<EGetPostPollActionType.Failed>;

export type TGetPostPoll = TGetPostPollLoading | TGetPostPollSucceed | TGetPostPollFailed;
