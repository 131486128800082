import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { render } from 'react-dom';

import { IParseJournalBannersItem } from './parseJournalBanners';
import { PostBannerDecorator } from '../../components/PostBannerDecorator';

interface IUseContentJournalBannersParams {
  onClick?(url: string, position: IParseJournalBannersItem['position']): void;
}

/**
 * Рендерит и управляет баннерами в посте
 */
export const useContentJournalBanners = (
  extractedJournalBannersItems: IParseJournalBannersItem[],
  onClick?: IUseContentJournalBannersParams['onClick'],
) => {
  /** Отправляет клик по баннеру */
  const handlePostBannerClick = useCallback(
    (url: string, position: IParseJournalBannersItem['position']) => {
      if (!url || !onClick) {
        return;
      }

      onClick(url, position);
    },
    [onClick],
  );

  /** Рендерит баннеры */
  useEffect(() => {
    extractedJournalBannersItems.forEach(banner => {
      const root = document.getElementById(banner.id);

      if (root) {
        render(
          <PostBannerDecorator onClick={url => handlePostBannerClick(url, banner.position)}>
            {/* eslint-disable react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: banner.outerHtml }} />
          </PostBannerDecorator>,
          root,
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
