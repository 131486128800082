import React from 'react';
import { Image, UIText1 } from '@cian/ui-kit';

import { IPollInfoAnswerSchema } from '../../../../repositories/journal/entities/poll/PollInfoAnswerSchema';
import * as s from './ListItemContent.css';

interface IListItemContent extends Pick<IPollInfoAnswerSchema, 'image' | 'name'> {
  children?: React.ReactNode;
}

/**
 * Базовый контент элемента списка
 */
export const ListItemContent = ({ image, name, children }: IListItemContent) => {
  return (
    <div className={s['wrapper']}>
      <Image src={image} width={33} height={33} />
      <UIText1>{name}</UIText1>
      {children}
    </div>
  );
};
