import * as React from 'react';
import { useSelector } from 'react-redux';

import { PostStatusLabel } from '../../components/PostStatusLabel';
import { isOwner } from '../../utils';
import { transformStatusToLabel } from './utils/transformStatusToLabel';
import { selectPost } from '../../selectors/post';
import { selectUser } from '../../selectors/user';
import { EType } from '../../repositories/journal/entities/journal/JournalAttributesSchema';

/**
 * Контейнер с плашкой статуса поста
 */
export const PostStatusLabelContainer = () => {
  const post = useSelector(selectPost);
  const user = useSelector(selectUser);

  if (!('id' in post)) {
    return null;
  }

  const {
    attributes: { type, status, authorId },
  } = post;
  const isUserOwner = isOwner(authorId || undefined, (user.isAuthenticated && user.userId) || undefined);
  const { label, type: labelType } = transformStatusToLabel(status);

  if (![EType.Blogs, EType.Questions].includes(type) || !isUserOwner) {
    return null;
  }

  return <PostStatusLabel type={labelType} label={label} />;
};
