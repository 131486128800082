/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TPollVoteModel,
  IPollVoteRequest,
  IMappers,
  IPollVoteResponse,
  IPollVoteResponseError,
  TPollVoteResponse,
} from './types';

const defaultConfig: TPollVoteModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'journal',
  pathApi: '/v1/poll-vote/',
  hostType: 'api',
} as TPollVoteModel;

function createPollVoteModel(parameters: IPollVoteRequest): TPollVoteModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IPollVoteOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IPollVoteRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchPollVote<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IPollVoteOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TPollVoteResponse> {
  const { response, statusCode } = await httpApi.fetch(createPollVoteModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IPollVoteResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IPollVoteResponseError);
    }
  }

  return { response, statusCode } as TPollVoteResponse;
}

export { defaultConfig, createPollVoteModel, fetchPollVote };
