import { TAdFoxBannerProps, TAdFoxBannerTemplate } from '@cian/adfox-component';

export interface IParseAdfoxItem {
  /** Полное HTML-тело шаблона adfox */
  outerHtml: string;
  /** Параметры шаблона adfox */
  data: TAdFoxBannerProps & TAdFoxBannerTemplate;
}

export interface IParseAdfox {
  /** HTML-контент */
  html: string;
  /** Коллекция параметров для шаблонов adfox */
  items: IParseAdfoxItem[];
}

/**
 * 1. Извлекает из HTML шаблон adfox-баннера с параметрами
 * 2. Вырезает шаблон adfox-баннера из HTML и заменяет пустым div'ом
 */
export const parseAdfox = (html: string): IParseAdfox => {
  const pattern = /<div[^>]*?(?:class="__adfox")>(.*?)<\/div>/gis;
  const items = [] as IParseAdfoxItem[];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  while ((match = pattern.exec(html))) {
    let parsedData;
    // Если парсинг сломался, то не добавляем в коллекцию
    try {
      parsedData = JSON.parse(
        match[1]
          .replace(/((\r)?\n)|\s/g, '')
          .replace(/(([\w]+):)/g, '"$2":')
          .replace(/'|(",(?:}))/g, '"')
          .replace(/",}/g, '"}'),
      );
    } catch (e) {
      parsedData = null;
    }

    if (parsedData) {
      items.push({
        outerHtml: match[0],
        data: parsedData,
      });
    }
  }

  return {
    html: items.reduce((acc, item) => {
      return acc.replace(item.outerHtml, `<div id="${item.data.containerId}" style="position: relative"></div>`);
    }, html),
    items,
  };
};
