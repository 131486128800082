/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IPollInfoAnswerSchema } from '../../entities/poll/PollInfoAnswerSchema';

export type TGetPollModel = IModel<IGetPollRequest, TGetPollResponse>;

export interface IGetPollRequest {
  /** ID сущности, к которой прикреплен опрос (статья, новость) и с которой голосовали **/
  objectPk?: number | null;
}

export type TGetPollResponse = IGetPollResponse200 | IGetPollResponse400;

export interface IGetPollResponse200 extends IModelResponse<IGetPollResponse> {
  statusCode: 200;
}

export interface IGetPollResponse400 extends IModelResponse<IGetPollResponseError> {
  statusCode: 400;
}

export interface IGetPollResponse {
  /** Всего проголосовавших **/
  answerCount?: number | null;
  /** Ответы **/
  answers: IPollInfoAnswerSchema[];
  /** Дата окончания **/
  dateEnd?: string | null;
  /** Дата начала **/
  dateStart: string;
  /** ID опроса **/
  id: number;
  /** Разрешены ли несколько ответов **/
  isMultiAnswer: boolean;
  /** Статус опроса **/
  status: EStatus;
  /** Текст вопроса **/
  title: string;
  /** ID ответов, за которые голосовал юзер **/
  userVote: number[];
}

export interface IGetPollResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetPollResponse | IGetPollResponseError;

export enum EStatus {
  /** Активный **/
  Active = 'active',
  /** Скрыт **/
  Hidden = 'hidden',
  /** Завершен **/
  Inactive = 'inactive',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetPollResponse): TResponse200;
  400(response: IGetPollResponseError): TResponse400;
}
