import { createSelector } from 'reselect';

import { buildPostUrl, IPostParentItem } from '../../utils';
import { selectCurrentPageMeta } from '../pagesMeta';
import { IApplicationState } from '../../types/redux';
import { IBreadCrumbNew } from '../../types/bread_crumb';
import { TPostState } from '../../types/post';

export interface ISelectCurrentBreadCrumbsOptions {
  pathname: string;
  parents?: IPostParentItem[];
  post?: TPostState;
  withTag?: string;
  search?: string;
  tag?: string;
}

/**
 * Строит и возвращает коллекцию "хлебных крошек"
 */
export const selectCurrentBreadCrumbs = createSelector(
  [
    (state: IApplicationState) => state.pagesMeta.items,
    (state: IApplicationState, options: ISelectCurrentBreadCrumbsOptions) =>
      selectCurrentPageMeta(state, options.pathname),
    (_: IApplicationState, options: ISelectCurrentBreadCrumbsOptions) => options,
  ],
  (pagesMeta, { pathname, breadCrumbName, breadCrumbParents, type }, options): IBreadCrumbNew[] => {
    const breadCrumbs = breadCrumbParents.reduce((acc, path) => {
      const { pathname, breadCrumbName } = pagesMeta.find(pageMeta => pageMeta.pathname === path) || {};

      if (pathname && breadCrumbName) {
        return [...acc, { pathname, breadCrumbName, isParent: true }];
      }

      return acc;
    }, []);

    if (type === 'postCard') {
      if (options?.parents?.length) {
        // самая последняя рубрика считается самой свежей
        const { name, pathname } = options.parents[options.parents.length - 1];

        breadCrumbs.push({
          pathname,
          breadCrumbName: name,
          isParent: true,
        });
      }

      if (options?.post && 'id' in options.post) {
        const {
          id,
          attributes: { slug, type, title },
        } = options.post;

        breadCrumbs.push({
          pathname: buildPostUrl({ type, slug, id }),
          breadCrumbName: title,
          isParent: false,
        });
      }

      return breadCrumbs;
    }

    // Псевдорубрика Статьи не должна попасть в хлебные крошки
    if (pathname !== '/stati/') {
      breadCrumbs.push({
        pathname,
        breadCrumbName,
        isParent: Boolean(options?.withTag) || Boolean(options?.search) || Boolean(options?.tag),
      });
    }

    if (options?.withTag) {
      breadCrumbs.push({
        pathname: '',
        breadCrumbName: options.withTag.charAt(0).toUpperCase() + options.withTag.slice(1),
        isParent: false,
      });
    } else if (options?.search) {
      breadCrumbs.push({
        pathname: '',
        breadCrumbName: options.search.charAt(0).toUpperCase() + options.search.slice(1),
        isParent: false,
      });
    } else if (options?.tag) {
      breadCrumbs.push({
        pathname: '',
        breadCrumbName: `#${options.tag}`,
        isParent: false,
      });
    }

    return breadCrumbs;
  },
);
