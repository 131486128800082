import React from 'react';
import { useSelector } from 'react-redux';

import { PostParents, PostParentsSkeleton } from '../../components/PostParents';
import { usePostParents } from '../../utils';
import { selectPost } from '../../selectors/post';
import { ERequestStatus } from '../../types/requestStatus';

/**
 * Контейнер родителей поста (категории или рубрики)
 */
export const PostParentsContainer = () => {
  const post = useSelector(selectPost);
  const parents = usePostParents();

  if (post.status === ERequestStatus.Loading) {
    return <PostParentsSkeleton />;
  }

  if (!parents?.length) {
    return null;
  }

  return <PostParents items={parents} />;
};
