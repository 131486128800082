import { PostTypeAsPostCardPrefix } from '../../constants';

/**
 * Является ли path адресом карточки поста
 * Правильные кейсы:
 * - {prefix}-{slug}-{id}
 * - {prefix}-{id}
 */
export const isPostCardPath = (path: string, prefixes = Object.values(PostTypeAsPostCardPrefix)) => {
  return new RegExp(`^/(${prefixes.join('|')})-([^/]+-)?[0-9]+/?$`).test(path);
};
