import { TThunkAction } from '../../../types/redux';
import { fetchPollVote, TPollVoteResponse, IPollVoteRequest } from '../../../repositories/journal/v1/poll-vote';
import { throwError } from '../../../utils';
import { votePostPollSucceed } from './votePostPollSucceed';

interface IVotePostPoll extends Pick<IPollVoteRequest, 'pollId'> {
  postId: IPollVoteRequest['objectPk'];
  votes: IPollVoteRequest['vote'];
}

export const votePostPoll = ({ postId, pollId, votes }: IVotePostPoll): TThunkAction<Promise<boolean>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    try {
      const { response, statusCode }: TPollVoteResponse = await fetchPollVote({
        httpApi,
        parameters: {
          objectPk: postId,
          pollId,
          vote: votes,
        },
      });

      if (statusCode !== 200) {
        throwError({ message: 'Не удалось проголосовать', statusCode, domain: 'actions.votePostPoll' });

        return false;
      }

      dispatch(votePostPollSucceed({ vote: response.vote }));

      return true;
    } catch (e) {
      logger.error(e, {
        domain: 'actions.votePostPoll',
      });

      return false;
    }
  };
};
