import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeviceType } from '@cian/ui-kit';
import { useLocation } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { clsx } from 'clsx';

import { Routes } from '../Routes';
import { Regions } from '../Regions/Regions';
import { Seo } from '../seo/seo';
import { ReadTopicDesktop } from '../read_topic/read_topic';
import { SeoUrlsDesktop } from '../seo_urls/seo_urls';
import { AdfoxBannerFooter } from '../../components/AdfoxBanner';
import { Search } from '../Search/Search';
import { DemandActivityPopup } from '../../components/realtor_demand/demand_activity_popup';
import { selectSearchShown } from '../../selectors/searchShown';
import { ApplicationContext } from '../../utils';
import { MainMenuContainer } from '../MainMenu';
import { SimilarArticlesContainer } from '../SimilarArticles';
import { setPageType } from '../../actions/settings';
import { selectPageType } from '../../selectors/settings/selectPageType';
import s from './Layout.css';

export function Layout() {
  const dispatch = useDispatch();
  const isSearchShown = useSelector(selectSearchShown);
  const pageType = useSelector(selectPageType);
  const location = useLocation();
  const { pathname } = location;
  const {
    custom: { isSimilarArticlesBlockEnabled },
  } = useContext(ApplicationContext);

  const deviceType = useDeviceType();
  const isDesktop = () => {
    return deviceType === 'desktop';
  };

  /** Сохраняет тип страницы в стор (карточка или иное) */
  const handleRouteRender = useCallback(
    ({ match: { params } }: RouteComponentProps<{ id?: string }>) => {
      dispatch(setPageType(params.id ? 'card' : 'other'));
    },
    [dispatch],
  );

  return (
    <section className={`${s['content']} ${isSearchShown ? s['content-search-visible'] : ''} ${s['_with-new-menu']}`}>
      <Regions />
      <div
        className={`
                      ${s['content__container']} cg-container-fluid-xs
                      ${isSearchShown ? s['search-visible'] : ''}
                      ${s['_with-new-menu']}
                    `}
      >
        <div className={`${s['sidebar-new']}`}>
          <MainMenuContainer />
        </div>

        <div className="cg-row">
          <div
            className={`
                          ${s['content__container_info']}
                          cg-col-20
                          cg-col-xs-24
                          ${s['_with-new-menu']}
                        `}
          >
            <div className={`cg-row ${s['article-container']}`}>
              <Seo />
              <Routes onRender={handleRouteRender} />
            </div>
          </div>
        </div>
      </div>
      <div id="content-footer" className={s['content__read-topic']}>
        <div className={`cg-container-fluid-xs ${s['content-bottom-block']}`}>
          {isSimilarArticlesBlockEnabled && pageType === 'card' ? (
            <div className={`cg-row`}>
              <div className="cg-col-20 cg-skip-4 cg-col-xs-16 cg-skip-xs-0">
                {isDesktop() && <SimilarArticlesContainer />}
              </div>
            </div>
          ) : (
            <>
              <div className={`cg-row ${s['read-topic-desktop']}`}>
                <div className="cg-col-20 cg-skip-4 cg-col-xs-16 cg-skip-xs-0">
                  {isDesktop() && <ReadTopicDesktop />}
                </div>
              </div>
              <div className={`cg-row ${s['read-topic-desktop']}`}>
                <div className="cg-col-20 cg-skip-4 cg-col-xs-16 cg-skip-xs-0">{isDesktop() && <SeoUrlsDesktop />}</div>
              </div>
            </>
          )}

          <div className={clsx('cg-row', s['adfox-banner-footer'])}>
            <AdfoxBannerFooter />
          </div>
        </div>
      </div>
      <Search />
      <DemandActivityPopup pathname={pathname} />
    </section>
  );
}
