/* eslint-disable max-lines */
import { TDeviceType, useDeviceType } from '@cian/ui-kit';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { ca } from '@cian/analytics';
import { ThunkDispatch } from 'redux-thunk';

import { IBreadItem } from '../../types/bread_crumb';
import { setBreadCrumb } from '../../reducers/modules/bread_crumbs/bread_crumbs';
import { IConfig } from '../../types/config';
import { IApplicationState, IThunkContext, TReduxActions } from '../../types/redux';
import { ISeoItem } from '../../types/seo_data';
import { BreadCrumbs } from '../bread_crumbs/bread_crumbs';
import {
  cleanupStorage,
  submitAddQuestion,
  submitEditQuestion,
} from '../../reducers/modules/add_question/add_question';
import { fetchUser } from '../../reducers/modules/current_user';
import { uploadImage } from '../../reducers/modules/add_blog/add_blog';
import { getSeoSuccess } from '../../reducers/modules/seo/seo';
import { getRegions } from '../../reducers/modules/regions/regions';
import { IRegions } from '../../types/regions';
import { getTags } from '../../reducers/modules/tags/tags';
import { ITags } from '../../types/tags';
import { IClientAddForm, ICategory } from '../../types/client_add_form';
import { IPostItem } from '../../types/posts';
import { IPostCard } from '../../types/post_card';
import { IUser } from '../../types/user';
import { editCard, notEditCard, getPostCard } from '../../reducers/modules/post_card/post_card';
import * as Analytics from '../../../app/helpers/analytics_helper';
import { combineDataLayerObject } from '../../../app/helpers/combine_data_layer_object';
import { getBreadCrumbsNames } from '../../../app/helpers/breadcrumbs_helper';
import { isAdminOrModerator } from '../../../app/utils';
import ClientAddForm from '../../components/client_add_form/client_add_form';
import { IAnalytics } from '../../types/analytics';
import { prepareQSParam } from '../../utils';
import s from './add_question.css';
import clientAddFormStyles from '../../components/client_add_form/client_add_form.css';

interface IProps {
  regions: IRegions;
  tags: ITags;
  addQuestion: IClientAddForm;
  postCard: IPostCard;
  user: IUser;
  config: IConfig;
  deviceType: TDeviceType;
  analytics: IAnalytics;
}

interface IDispatchProps {
  setBreadCrumb(path: IBreadItem[]): void;
  getRegions(scheme: string, host: string): void;
  getTags(): void;
  getSeoSuccess(seo: ISeoItem): void;
  getPostCard(pathname: string, params?: { name: string; value: string | number }[]): void;
  cleanupStorage(): void;
  fetchUser(): void;
  notEditCard(): void;
  editCard(): void;
  uploadImage(image: string): void;
  submitAddQuestion(
    content: string,
    status: string,
    is_subscribed_to_comments: boolean,
    category: string,
    regions_id: number[],
    tags: string,
    title: string,
    imageData: string,
    userEmail: string,
    userName: string,
  ): void;
  submitEditQuestion(
    id: number,
    content: string,
    status: string,
    is_subscribed_to_comments: boolean,
    category: string,
    regions_id: number[],
    tags: string,
    title: string,
    image: string,
  ): void;
}

const AddQuestionContainer: React.FC<IProps & IDispatchProps & RouteComponentProps> = props => {
  const [editingItem, setEditingItem] = useState<IPostItem>();
  const deviceType = useDeviceType();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const qsPostSlugAndId = prepareQSParam(searchParams.get('slug-id'));

  const categories: ICategory[] = [
    { id: '', value: '', label: 'Не выбрана' },
    { id: 'city_realty', value: 'city_realty', label: 'Городская недвижимость' },
    { id: 'commerce_realty', value: 'commerce_realty', label: 'Коммерческая недвижимость' },
    { id: 'country_realty', value: 'country_realty', label: 'Загородная недвижимость' },
    { id: 'foreign_realty', value: 'foreign_realty', label: 'Зарубежная недвижимость' },
    { id: 'hypothec', value: 'hypothec', label: 'Ипотека' },
    { id: 'market_trend', value: 'market_trend', label: 'Тенденции рынка' },
  ];
  const contentLimit = 5000;

  useEffect(() => {
    const { config } = props;

    const crumbs: IBreadItem[] = [{ path: '/magazine' }, { path: '/forum-rieltorov' }, { path: '/add-vopros' }];

    if (typeof window !== 'undefined') {
      ca('pageviewSite', combineDataLayerObject(props.analytics, getBreadCrumbsNames(crumbs), deviceType));
    }

    props.setBreadCrumb(crumbs);
    props.getRegions(config.regionsApiSchemeNode, config.regionsApiHostNode);
    props.getTags();
    props.getSeoSuccess({
      seoTitle: 'Новый вопрос',
      seoUrl: 'https://cian.ru/add-vopros/',
      seoDescription: `ЦИАН - форум о недвижимости, советы риэлторов и других специалистов недвижимости. Задать вопрос риэлтору.`,
      seoKeywords: 'недвижимость, форум, советы, вопрос-ответ',
    });

    if (qsPostSlugAndId) {
      props.editCard();
      props.getPostCard(`/v1/get-question`, [
        {
          name: 'id',
          value: qsPostSlugAndId,
        },
        {
          name: 'user_id',
          value: props.user.userId,
        },
      ]);
    } else if (props.addQuestion.isEdit) {
      setEditingItem(props.postCard.item);
    }
    props.cleanupStorage();

    return () => {
      if (props.addQuestion.isEdit) {
        props.notEditCard();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.postCard.item.id) {
      setEditingItem(props.postCard.item);
    }
  }, [props.postCard.item]);

  const browserHistory = useHistory();
  useEffect(() => {
    if (props.addQuestion.item && props.addQuestion.item.id) {
      props.fetchUser();
      browserHistory.push(`/forum-rieltorov-${props.addQuestion.item.attributes.slug}-${props.addQuestion.item.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addQuestion.item]);

  const onSubmitAddQuestion = (
    content: string,
    status: string,
    isSubscribedToComments: boolean,
    category: string,
    regionsId: number[],
    tags: string,
    title: string,
    imageData: string,
    userEmail: string,
    userName: string,
  ) => {
    Analytics.addPost('add_question');

    if (isSubscribedToComments) {
      Analytics.subscribeComments('new_comments', 'Magazine_subscription');
    }

    props.submitAddQuestion(
      content,
      status,
      isSubscribedToComments,
      category,
      regionsId,
      tags,
      title,
      imageData,
      userEmail,
      userName,
    );
  };

  const onSubmitEditQuestion = (
    id: number,
    content: string,
    status: string,
    isSubscribedToComments: boolean,
    category: string,
    regionsId: number[],
    tags: string,
    title: string,
    imageData: string,
  ) => {
    props.submitEditQuestion(id, content, status, isSubscribedToComments, category, regionsId, tags, title, imageData);
  };

  function getCategoriesWithSecret() {
    return [...categories, { id: 'specialist', value: 'specialist', label: 'Специалист-специалисту' }];
  }

  function getCategories() {
    const { user } = props;

    if (isAdminOrModerator(user)) {
      return getCategoriesWithSecret();
    }
    if (user.userId === -1 || (user.rating || 0) < 50) {
      return categories;
    }

    return getCategoriesWithSecret();
  }

  return (
    <div className={s['add-question__wrapper']}>
      <div className={s['breadcrumbs-wrap']}>
        <BreadCrumbs />
      </div>
      <ClientAddForm
        user={props.user}
        item={props.addQuestion.item}
        heading="задать вопрос"
        entityName="вопроса"
        styles={clientAddFormStyles}
        showLawBlock={false}
        showDescribeBlock={false}
        showImage={false}
        showSubscribe={true}
        tags={props.tags.items.map(tag => tag.attributes.name)}
        onSubmitAddForm={onSubmitAddQuestion}
        regions={props.regions.items}
        error={props.addQuestion.error}
        fetching={props.addQuestion.fetching}
        categories={getCategories()}
        contentLimit={contentLimit}
        editingItem={editingItem}
        isEdit={props.addQuestion.isEdit}
        onSubmitEditForm={onSubmitEditQuestion}
        type="question"
        deviceType={deviceType}
        uploadImage={(img: string) => props.uploadImage(img)}
      />
      <div className={s['breadcrumbs-wrap-mobile']}>
        <BreadCrumbs mobile={true} />
      </div>
    </div>
  );
};

function mapStateToProps(state: IApplicationState): IProps {
  const { addQuestion, user, regions, tags, postCard, config, deviceType, analytics } = state;

  return {
    addQuestion,
    user,
    regions,
    tags,
    postCard,
    config,
    deviceType,
    analytics,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IApplicationState, IThunkContext, TReduxActions>): IDispatchProps {
  return {
    setBreadCrumb: (path: IBreadItem[]) => {
      dispatch(setBreadCrumb(path));
    },
    getRegions: (scheme: string, host: string) => {
      dispatch(getRegions(scheme, host));
    },
    getTags: () => {
      dispatch(getTags());
    },
    getSeoSuccess: (seo: ISeoItem) => {
      dispatch(getSeoSuccess(seo));
    },
    getPostCard: (pathname: string, params?: { name: string; value: string | number }[]) => {
      dispatch(getPostCard(pathname, params));
    },
    cleanupStorage: () => {
      dispatch(cleanupStorage());
    },
    fetchUser: () => {
      dispatch(fetchUser());
    },
    notEditCard: () => {
      dispatch(notEditCard());
    },
    editCard: () => {
      dispatch(editCard());
    },
    uploadImage: (image: string) => dispatch(uploadImage(image)),
    submitAddQuestion: (
      content: string,
      status: string,
      isSubscribedToComments: boolean,
      category: string,
      regionsId: number[],
      tags: string,
      title: string,
      imageData: string,
      userEmail: string,
      userName: string,
    ) => {
      dispatch(
        submitAddQuestion(
          content,
          status,
          isSubscribedToComments,
          category,
          regionsId,
          tags,
          title,
          imageData,
          userEmail,
          userName,
        ),
      );
    },
    submitEditQuestion: (
      id: number,
      content: string,
      status: string,
      isSubscribedToComments: boolean,
      category: string,
      regionsId: number[],
      tags: string,
      title: string,
      imageData: string,
    ) => {
      dispatch(
        submitEditQuestion(id, content, status, isSubscribedToComments, category, regionsId, tags, title, imageData),
      );
    },
  };
}

export const AddQuestion = connect(mapStateToProps, mapDispatchToProps)(AddQuestionContainer);
