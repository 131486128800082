import { fetchGetPoll, TGetPollResponse } from '../../../repositories/journal/v1/get-poll';
import { mapPostPoll, throwError } from '../../../utils';
import { getPostPollLoading } from './getPostPollLoading';
import { getPostPollSucceed } from './getPostPollSucceed';
import { getPostPollFailed } from './getPostPollFailed';
import { TThunkAction } from '../../../types/redux';

interface IGetPostCardNews {
  postId: number;
}

export const getPostPoll = ({ postId }: IGetPostCardNews): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getPostPollLoading());

    try {
      const { response, statusCode }: TGetPollResponse = await fetchGetPoll({
        httpApi,
        parameters: {
          objectPk: postId,
        },
      });

      if (statusCode !== 200) {
        throwError({ message: 'Не удалось загрузить Опрос', domain: 'actions.getPostPoll', statusCode });

        return;
      }

      dispatch(getPostPollSucceed(mapPostPoll(response)));
    } catch (e) {
      logger.error(e, {
        domain: 'actions.getPostPoll',
      });

      dispatch(getPostPollFailed());
    }
  };
};
