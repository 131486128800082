import { EStatus } from '../../../../repositories/journal/entities/journal/JournalAttributesSchema';

export const transformStatusToLabel = (status: EStatus) => {
  switch (status) {
    case EStatus.Publish:
    case EStatus.Checked:
    case EStatus.Unchecked:
      return { label: 'Опубликовано', type: EStatus.Publish };

    case EStatus.Decline:
    case EStatus.Hidden:
    case EStatus.Removed:
    case EStatus.TrashBin:
      return { label: 'Отклонено', type: EStatus.Decline };

    case EStatus.Draft:
    default:
      return { label: 'Черновик', type: EStatus.Draft };
  }
};
