import React, { useCallback, useState } from 'react';
import { plural } from '@cian/utils/lib/shared/plural';
import { Button, UIHeading1, UIHeading2, useDeviceType } from '@cian/ui-kit';

import { getListItemComponent } from './utils';
import { IPostPoll } from '../../types/postPoll';
import * as s from './PostPoll.css';

interface IPostPollParams extends IPostPoll {
  onSubmit(ids: number[]): Promise<boolean>;
}

/**
 * Опрос в посте
 */
export const PostPoll = ({
  id,
  title,
  userVote,
  answerCount,
  isMultiAnswer,
  dateEnd,
  pollStatus,
  answers,
  onSubmit,
}: IPostPollParams) => {
  const deviceType = useDeviceType();
  const isUserVoted = Boolean(userVote.length);
  const { component: ListItemComponent, name: listItemComponentName } = getListItemComponent({
    pollStatus,
    dateEnd,
    isMultiAnswer,
    isUserVoted,
  });
  const HeadingComponent = deviceType === 'phone' ? UIHeading2 : UIHeading1;
  const [checkedAnswerIds, setCheckedAnswerIds] = useState(new Map(answers.map(answer => [answer.id, false])));
  const [isDisabled, setIsDisabled] = useState(false);

  /** Отправляет выбранные значения родителю */
  const handleSubmit = useCallback(async () => {
    const answerIds: number[] = [];

    checkedAnswerIds.forEach((value, key) => {
      if (value) {
        answerIds.push(key);
      }
    });

    setIsDisabled(true);
    await onSubmit(answerIds);
    setIsDisabled(false);
  }, [checkedAnswerIds, onSubmit]);

  /** Сохраняет выбранные значения в стейт */
  const handleChange = useCallback(
    (answerId: number, isChecked: boolean) => {
      if (listItemComponentName === 'CheckboxListItem') {
        checkedAnswerIds.set(answerId, isChecked);
        setCheckedAnswerIds(checkedAnswerIds);

        return;
      }

      if (listItemComponentName === 'RadioListItem') {
        checkedAnswerIds.forEach((value, key) => {
          checkedAnswerIds.set(key, key === answerId ? isChecked : false);
        });

        setCheckedAnswerIds(checkedAnswerIds);
        void handleSubmit();

        return;
      }
    },
    [checkedAnswerIds, handleSubmit, listItemComponentName],
  );

  return (
    <div className={`${s['wrapper']} ${isDisabled ? s['_loading'] : ''}`}>
      <HeadingComponent>{title}</HeadingComponent>

      {isUserVoted && (
        <div className={s['results']}>
          Всего {plural(Number(answerCount), ['проголосовал', 'проголосовали', 'проголосовали'])} {Number(answerCount)}{' '}
          {plural(Number(answerCount), ['человек', 'человека', 'человек'])}
        </div>
      )}

      <div className={s['list-wrapper']}>
        {answers.map((item, index) => (
          <ListItemComponent
            key={index}
            pollId={id}
            isDisabled={isDisabled}
            {...item}
            onChange={isChecked => handleChange(item.id, isChecked)}
          />
        ))}
      </div>

      {isMultiAnswer && !isUserVoted && (
        <Button
          theme={'fill_white_primary'}
          fullWidth={deviceType === 'phone'}
          onClick={handleSubmit}
          loading={isDisabled}
        >
          Ответить
        </Button>
      )}
    </div>
  );
};
