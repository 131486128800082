import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Post, SkeletonPost } from '../../components/Post';
import { findPostRegionName } from './utils';
import {
  buildPostUrl,
  isOwner,
  parsePostCardPath,
  sanitizePostContent,
  useEffectOnce,
  useGetPost,
  usePreparedContent,
} from '../../utils';
import { selectPost } from '../../selectors/post';
import { selectUser } from '../../selectors/user';
import { selectCurrentRegion } from '../../selectors/geo/currentRegion';
import { selectRegions } from '../../selectors/regions/selectRegions';
import {
  getIsPostCommentsUserSubscribed,
  getPost,
  getPostLikeCounts,
  resetPost,
  updateBlogPostStatus,
  updateQuestionPostStatus,
} from '../../actions/post';
import * as Analytics from '../../../app/helpers/analytics_helper';
import { POST_COMMENTS_ID, PostCardPrefixAsPostType } from '../../constants';
import { ERequestStatus } from '../../types/requestStatus';
import { TThunkDispatch } from '../../types/redux';
import { EStatus, EType } from '../../repositories/journal/entities/journal/JournalAttributesSchema';

export const PostContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { pathname } = useLocation();
  const { id: qsPostId, prefix } = parsePostCardPath(pathname);
  const post = useSelector(selectPost);
  const user = useSelector(selectUser);
  const currentRegion = useSelector(selectCurrentRegion);
  const { items: dictionaryRegions } = useSelector(selectRegions);
  const postType = prefix && PostCardPrefixAsPostType[prefix];
  const history = useHistory();

  usePreparedContent();
  useGetPost(
    () => {
      const run = async () => {
        await dispatch(getPost({ id: qsPostId }));
        await dispatch(getIsPostCommentsUserSubscribed({ id: qsPostId }));

        if (postType && (EType.Articles === postType || EType.News === postType)) {
          await dispatch(getPostLikeCounts({ postId: qsPostId, postType }));
        }
      };

      void run();
    },
    { dependencyNames: ['pathname'] },
  );

  useEffectOnce(() => {
    return () => {
      dispatch(resetPost());
    };
  });

  /** Отправляет пост блога или вопрос на форму редактирования */
  const handleEditButtonClick = useCallback(() => {
    if (!('id' in post) || ![EType.Blogs, EType.Questions].includes(post.attributes.type)) {
      return;
    }

    const {
      id,
      attributes: { type, slug },
    } = post;

    history.push(`/${type === EType.Blogs ? 'add-blog' : 'add-vopros'}/?slug-id=${slug}-${id}`);
  }, [history, post]);

  /** Отправляет пост блога или вопрос на публикацию */
  const handlePublishButtonClick = useCallback(() => {
    if (!('id' in post) || ![EType.Blogs, EType.Questions].includes(post.attributes.type)) {
      return;
    }

    const {
      id,
      attributes: { type },
    } = post;

    Analytics.addPost('add_post');

    if (type === EType.Blogs) {
      void dispatch(updateBlogPostStatus({ id }));
    } else {
      void dispatch(updateQuestionPostStatus({ id }));
    }
  }, [dispatch, post]);

  if (post.status === ERequestStatus.Loading) {
    return <SkeletonPost />;
  }

  if (!('id' in post)) {
    return null;
  }

  const {
    id,
    attributes: {
      commentsCount,
      datePublish,
      image,
      numViews,
      subtitle,
      title,
      type,
      regions,
      slug,
      updatedAt,
      authorObject,
      authorId,
      content,
      preparedContent,
      status,
      disableComments,
      blockReason,
    },
  } = post;
  const isUserOwner = isOwner(authorId || undefined, (user.isAuthenticated && user.userId) || undefined);

  return (
    <Post
      authorPostsCount={undefined}
      authorId={authorId || undefined}
      authorName={authorObject?.fullName || undefined}
      commentsCount={commentsCount}
      content={sanitizePostContent(preparedContent || content || '')}
      dateModified={updatedAt}
      datePublished={datePublish}
      image={image}
      numViews={numViews}
      postCommentsUrl={buildPostUrl({ type, slug, id, anchor: POST_COMMENTS_ID })}
      postRegionName={findPostRegionName({
        globalRegionId: currentRegion.id,
        postRegionIds: regions || [],
        dictionaryRegions,
      })}
      subtitle={[EType.Articles, EType.News].includes(type) ? subtitle : undefined}
      title={title}
      type={type}
      blockReason={[EType.Blogs, EType.Questions].includes(type) && isUserOwner ? blockReason : undefined}
      canViewComments={!disableComments}
      canPublishPost={[EType.Blogs, EType.Questions].includes(type) && isUserOwner && status === EStatus.Draft}
      canEditPost={[EType.Blogs, EType.Questions].includes(type) && isUserOwner}
      onEditButtonClick={handleEditButtonClick}
      onPublishButtonClick={handlePublishButtonClick}
    />
  );
};
