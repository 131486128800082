import { ERequestStatus } from '../../types/requestStatus';
import { TPostPollState } from '../../types/postPoll';
import {
  EGetPostPollActionType,
  TPostPollActions,
  EResetPostPollActionType,
  EVotePostPollActionType,
} from '../../actions/postPoll';

export const defaultState: TPostPollState = {
  status: ERequestStatus.Initial,
};

export const postPollReducer = (state = defaultState, action: TPostPollActions) => {
  switch (action.type) {
    case EGetPostPollActionType.Loading:
      return { ...state, status: ERequestStatus.Loading };

    case EGetPostPollActionType.Succeed:
      return { ...state, ...action.payload, status: ERequestStatus.Succeed };

    case EGetPostPollActionType.Failed:
      return { ...state, status: ERequestStatus.Failed };

    case EResetPostPollActionType.Reset:
      return defaultState;

    case EVotePostPollActionType.Succeed:
      return { ...state, userVote: action.payload.vote };

    default:
      return state;
  }
};
