import { ITypedReduxAction } from '../../types/redux/actionType';
import { IPostContentPhotoGallery } from '../../types/postContentPhotoGalleries';

export enum EPostContentPhotoGalleryActionType {
  Set = 'postContentPhotoGalleries/set',
  Reset = 'postContentPhotoGalleries/reset',
}

export type TPostContentPhotoGallerySet = ITypedReduxAction<
  EPostContentPhotoGalleryActionType.Set,
  IPostContentPhotoGallery
>;

export type TPostContentPhotoGalleryReset = ITypedReduxAction<EPostContentPhotoGalleryActionType.Reset>;

export type TPostContentPhotoGalleryActions = TPostContentPhotoGallerySet | TPostContentPhotoGalleryReset;
