import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { hydrate } from 'react-dom';
import { connect } from '@cian/adfox-component';
import { useLocation } from 'react-router-dom';

import { bannerWrapper } from '../../components/TGBanner/TGBannerEmpty';
import { IParseAdfoxItem, parseAdfox } from './parseAdfox';
import { unescapeHtml } from '../html';

interface IUseContentParams {
  canParse: boolean;
  canRender: boolean;
  postContent: string;
  setPostContent: Dispatch<SetStateAction<string>>;
  done(): void;
}

/**
 * Рендерит баннеры Adfox
 */
export const useContentAdfoxBannersNew = ({
  canParse,
  canRender,
  postContent,
  setPostContent,
  done,
}: IUseContentParams) => {
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const [isParsed, setIsParsed] = useState(false);
  const [parsedParams, setParsedParams] = useState<IParseAdfoxItem[]>([]);

  /** Сбрасывает стэйт при клиентском переходе с поста на пост */
  useEffect(() => {
    if (pathname !== prevPathname) {
      setPrevPathname(pathname);
      setIsParsed(false);
      setParsedParams([]);
    }
  }, [pathname, prevPathname]);

  /** Рендерит баннеры */
  useEffect(() => {
    if (!parsedParams.length || !canRender) {
      return;
    }

    parsedParams.forEach(banner => {
      const { ownerId, containerId, params } = banner.data;
      const Component = connect(bannerWrapper(() => null));

      const root = document.getElementById(containerId);

      if (root) {
        hydrate(<Component ownerId={ownerId} params={params} width="100%" height="0" />, root);
      }
    });
  }, [canRender, parsedParams]);

  /** Парсит параметры баннеров */
  useEffect(() => {
    if (!canParse || isParsed) {
      return;
    }

    const { items, html } = parseAdfox(unescapeHtml(postContent));

    if (items.length) {
      setPostContent(html);
      setParsedParams(items);
    }

    setIsParsed(true);
    done();
  }, [canParse, done, isParsed, postContent, setPostContent]);
};
