export interface IParseYoutubeItem {
  /** Полное HTML-тело iframe */
  outerHtml: string;
  /** Id ролика */
  id: string;
}

export interface IParseYoutube {
  /** HTML-контент */
  html: string;
  /** Коллекция параметров для ролика */
  items: IParseYoutubeItem[];
}

/**
 * 1. Извлекает из HTML все iframe с параметрами
 * 2. Вырезает все iframe из HTML и заменяет пустым div'ом
 */
export const parseYoutube = (html: string): IParseYoutube => {
  const pattern = /<iframe[^>]*?(?:src="(?:https:)?\/\/www\.youtube\.com\/embed\/([^?]+)[^"]*?")[^>]*?><\/iframe>/gis;
  const items = [] as IParseYoutubeItem[];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  while ((match = pattern.exec(html))) {
    items.push({
      outerHtml: match[0],
      id: match[1],
    });
  }

  return {
    html: items.reduce((acc, item) => {
      return acc.replace(item.outerHtml, `<div id="${item.id}"></div>`);
    }, html),
    items,
  };
};
