import sanitizeHtml from 'sanitize-html';

/**
 * Производит санитизацию контента поста
 */
export const sanitizePostContent = (html: string) =>
  sanitizeHtml(html, {
    allowedTags: [
      'dl',
      'dt',
      'dd',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'p',
      'i',
      'tbody',
      'br',
      'em',
      'td',
      'a',
      'strong',
      'th',
      'img',
      'strike',
      'tr',
      'div',
      'u',
      'caption',
      'span',
      's',
      'colgroup',
      'ul',
      'span',
      'col',
      'li',
      'pre',
      'tfoot',
      'ol',
      'table',
      'code',
      'b',
      'thead',
      'iframe',
      'button',
      'figure',
      'figcaption',
      'link',
      'hr',
    ],
    allowedAttributes: {
      '*': [
        'id',
        'itemscope',
        'itemtype',
        'itemprop',
        'href',
        'align',
        'alt',
        'center',
        'bgcolor',
        'class',
        'src',
        'rel',
        'target',
        'style',
        'name',
        'data-*',
        'rowspan',
        'colspan',
      ],
      iframe: ['src', 'width', 'height', 'frameborder', 'allow', 'allowfullscreen'],
      link: ['href', 'rel', 'type', 'media'],
    },
    allowedIframeHostnames: [
      'www.youtube.com',
      'cdn.embedly.com',
      'yandex.ru',
      'www.instagram.com',
      'www.facebook.com',
      'vkvideo.ru',
      'vk.com',
    ],
  });
