import { EffectCallback, RefObject, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ERequestStatus } from '../../types/requestStatus';
import { useCurrentListing } from '../useCurrentListing';
import { selectListingAppendType } from '../../selectors/settings/selectListingAppendType';
import { setInitialPageNumber, setListingAppendType } from '../../actions/settings';
import { TDependencyName, useSensitiveParams } from '../useSensitiveParams';
import { selectInitialPageNumber } from '../../selectors/settings/selectInitialPageNumber';
import { preparePageNumber } from '../preparePageNumber';
import { IEventTrackingData } from '../useEventTracking';

interface IScrollOptions<T> {
  type?: 'top' | 'ref' | 'none';
  ref?: RefObject<T>;
}

interface IUseGetListingOptions<T> {
  dependencyNames?: TDependencyName[];
  eventTrackerData?: IEventTrackingData;
  scrollOptions?: IScrollOptions<T>;
}

/**
 * Выполняет callback для запроса листинга
 */
export const useGetComments = <T extends HTMLElement = HTMLDivElement>(
  callback: EffectCallback,
  {
    dependencyNames = ['page'],
    scrollOptions: { type: scrollType, ref: scrollRef } = { type: 'top' },
  }: IUseGetListingOptions<T> = {},
) => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const qsPage = preparePageNumber(searchParams.get('page') as string);
  const dispatch = useDispatch();
  const { status: listingStatus } = useCurrentListing();
  const appendType = useSelector(selectListingAppendType);
  const key = useSensitiveParams(dependencyNames);
  const [prevKey, setPrevKey] = useState(key);
  const initialPageNumber = useSelector(selectInitialPageNumber);

  /**
   * Запускает callback, если хотя бы соблюдено одно из условий:
   * - список находится в статусе инициализации
   * - слепок значений из чувствительных ключей изменился
   * Трекает callback, различая действие смены/подгрузки страницы
   */
  useEffect(() => {
    if (listingStatus !== ERequestStatus.Initial && key === prevKey) {
      return;
    }

    /**
     * Переписывает индекс страницы инициализации
     * только для режима set
     */
    if (appendType === 'set') {
      dispatch(setInitialPageNumber(qsPage));
    }

    callback();
    setPrevKey(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, listingStatus, prevKey]);

  /**
   * Скролим наверх пока идет загрузка
   * (не для режима подгрузки)
   */
  useEffect(() => {
    if (listingStatus !== ERequestStatus.Loading || scrollType === 'none' || appendType === 'append') {
      return;
    }

    if (scrollType === 'top') {
      window.scrollTo(0, 0);
    }

    if (scrollRef?.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [appendType, listingStatus, scrollRef, scrollType]);

  /**
   * Сбрасываем тип сохранения материалов на дефолтный
   * в любом конечном статусе запроса
   */
  useEffect(() => {
    if (listingStatus === ERequestStatus.Loading) {
      return;
    }

    dispatch(setListingAppendType('set'));
  }, [dispatch, listingStatus]);

  /**
   * initialPageNumber не должен быть меньше qsPage
   */
  useEffect(() => {
    if (qsPage < initialPageNumber) {
      dispatch(setInitialPageNumber(qsPage));
    }
  }, [dispatch, initialPageNumber, qsPage]);
};
