import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { hydrate } from 'react-dom';
import { useLocation } from 'react-router-dom';

import { YoutubeVideoPlayer } from '../../components/YoutubeVideoPlayer';
import { IParseYoutubeItem, parseYoutube } from './parseYoutube';

interface IUseContentParams {
  canParse: boolean;
  canRender: boolean;
  postContent: string;
  setPostContent: Dispatch<SetStateAction<string>>;
  done(): void;
}

/**
 * Рендерит ютюб-плеер
 */
export const useContentYoutubeVideosNew = ({
  canParse,
  canRender,
  postContent,
  setPostContent,
  done,
}: IUseContentParams) => {
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const [isParsed, setIsParsed] = useState(false);
  const [parsedParams, setParsedParams] = useState<IParseYoutubeItem[]>([]);

  /** Сбрасывает стэйт при клиентском переходе с поста на пост */
  useEffect(() => {
    if (pathname !== prevPathname) {
      setPrevPathname(pathname);
      setIsParsed(false);
      setParsedParams([]);
    }
  }, [pathname, prevPathname]);

  /** Рендерит youtube-вставки */
  useEffect(() => {
    if (!parsedParams.length || !canRender) {
      return;
    }

    parsedParams.forEach(({ id }) => {
      const root = document.getElementById(id);

      if (root) {
        hydrate(<YoutubeVideoPlayer id={id} />, root);
      }
    });
  }, [canRender, parsedParams]);

  /** Парсит параметры youtube-вставки */
  useEffect(() => {
    if (!canParse || isParsed) {
      return;
    }

    const { items, html } = parseYoutube(postContent);

    if (items.length) {
      setPostContent(html);
      setParsedParams(items);
    }

    setIsParsed(true);
    done();
  }, [canParse, done, isParsed, postContent, setPostContent]);
};
