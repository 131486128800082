import sanitizeHtml from 'sanitize-html';

import { unescapeHtml } from '../unescapeHtml';
import { IScript } from '../../../types/script';

/**
 * Производит санитизацию контента и выбирает из него скрипты
 */
export const makeHTMLFromString = (content: string) => {
  const markup = unescapeHtml(content);
  const scripts = [];
  const pattern = /<script.*?(?:src="(.*?)")?>(.*?)<\/script>/gi;

  let match;
  while ((match = pattern.exec(markup)) != null) {
    scripts.push({
      body: match[0],
      src: match[1],
      content: match[2],
    });
  }

  let text = markup;
  scripts.forEach((script: IScript) => {
    text = text.replace(script.body, '');
  });

  return {
    __html: sanitizeHtml(text, {
      allowedTags: [
        'dl',
        'dt',
        'dd',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'p',
        'i',
        'tbody',
        'br',
        'em',
        'td',
        'a',
        'strong',
        'th',
        'img',
        'strike',
        'tr',
        'div',
        'u',
        'caption',
        'span',
        's',
        'colgroup',
        'ul',
        'span',
        'col',
        'li',
        'pre',
        'tfoot',
        'ol',
        'table',
        'code',
        'b',
        'thead',
        'iframe',
        'button',
        'figure',
        'figcaption',
        'link',
        'hr',
      ],
      allowedAttributes: {
        '*': [
          'id',
          'itemscope',
          'itemtype',
          'itemprop',
          'href',
          'align',
          'alt',
          'center',
          'bgcolor',
          'class',
          'src',
          'rel',
          'target',
          'style',
          'name',
          'data-*',
          'rowspan',
          'colspan',
        ],
        iframe: ['src', 'width', 'height', 'frameborder', 'allow', 'allowfullscreen'],
        link: ['href', 'rel', 'type', 'media'],
      },
      allowedIframeHostnames: [
        'www.youtube.com',
        'cdn.embedly.com',
        'yandex.ru',
        'www.instagram.com',
        'www.facebook.com',
        'vkvideo.ru',
        'vk.com',
      ],
    }),
    scripts,
  };
};
