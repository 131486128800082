import { buildPostUrl } from '../buildPostUrl';
import { getPostParents } from '../post';
import { IPageInfoSchema } from '../../repositories/journal/entities/page_info/PageInfoSchema';
import { ERequestStatus } from '../../types/requestStatus';
import { IPost, IPostState } from '../../types/post';

interface IGetBreadCrumbs {
  currentPageMeta: IPageInfoSchema;
  pagesMeta: IPageInfoSchema[];
  post?: IPost & IPostState<ERequestStatus.Succeed>;
  withTag?: string;
  search?: string;
  tag?: string;
}

interface IGetBreadCrumbsOptions {
  withPostTitle?: boolean;
}

export interface IPostBreadCrumb {
  pathname: string;
  breadCrumbName: string;
  isParent: boolean;
}

/**
 * Формирует список хлебных крошек для всех разделов/страниц
 */
export const getBreadCrumbs = (
  { currentPageMeta, pagesMeta, post, withTag, search, tag }: IGetBreadCrumbs,
  { withPostTitle }: IGetBreadCrumbsOptions = {},
) => {
  const { pathname, breadCrumbName, breadCrumbParents, type } = currentPageMeta;
  const breadCrumbs = breadCrumbParents.reduce((acc, path) => {
    const { pathname, breadCrumbName } = pagesMeta.find(pageMeta => pageMeta.pathname === path) || {};

    if (pathname && breadCrumbName) {
      return [...acc, { pathname, breadCrumbName, isParent: true } as IPostBreadCrumb];
    }

    return acc;
  }, []);

  if (type === 'postCard') {
    if (!post) {
      return [];
    }

    const {
      id,
      attributes: { rubrics, category, slug, type, title },
    } = post;

    const parents = getPostParents({ rubrics, category, type, pagesMeta });

    if (parents.length) {
      // самая последняя рубрика считается самой свежей
      const { name, pathname } = parents[parents.length - 1];

      breadCrumbs.push({
        pathname,
        breadCrumbName: name,
        isParent: true,
      });
    }

    if (withPostTitle) {
      breadCrumbs.push({
        pathname: buildPostUrl({ type, slug, id }),
        breadCrumbName: title,
        isParent: false,
      });
    }

    return breadCrumbs;
  }

  // Псевдорубрика Статьи не должна попасть в хлебные крошки
  if (pathname !== '/stati/') {
    breadCrumbs.push({
      pathname,
      breadCrumbName,
      isParent: Boolean(withTag || search || tag),
    });
  }

  if (withTag) {
    breadCrumbs.push({
      pathname: '',
      breadCrumbName: withTag.charAt(0).toUpperCase() + withTag.slice(1),
      isParent: false,
    });
  } else if (search) {
    breadCrumbs.push({
      pathname: '',
      breadCrumbName: search.charAt(0).toUpperCase() + search.slice(1),
      isParent: false,
    });
  } else if (tag) {
    breadCrumbs.push({
      pathname: '',
      breadCrumbName: `#${tag}`,
      isParent: false,
    });
  }

  return breadCrumbs;
};
