import * as React from 'react';

import s from './DeclineReason.css';

export const DECLINE_REASONS: { [index: string]: string } = {
  ad_spam: 'Реклама, спам',
  obscene_language: 'Нецензурная лексика',
  rules_violation: 'Нарушение правил сайта',
};

export const DeclineReason = ({ code }: { code: string }) => (
  <div className={s['wrapper']}>
    <h5>причина отклонения:</h5>
    <span>{DECLINE_REASONS[code]}</span>
  </div>
);
