import React, { ChangeEvent, useCallback, useState } from 'react';
import { Button, useDeviceType } from '@cian/ui-kit';

import { Textarea } from '../../Textarea';
import { getDefinedValue } from '../utils/getDefinedValue';
import { POST_CONTENT_COMMENT_FORM_MAX_LENGTH } from '../../../constants';
import * as s from './EditCommentForm.css';

export interface IEditCommentForm {
  onSubmit(value: string): void;
  onCancel(e: React.MouseEvent): void;
  value?: string;
  isDisabled?: boolean;
  onChangeValue?(value: string): void;
}

/**
 * Форма для редактирования комментария
 */
export const EditCommentForm = ({ onSubmit, value, isDisabled, onChangeValue, onCancel }: IEditCommentForm) => {
  const [innerValue, setInnerValue] = useState('');
  const deviceType = useDeviceType();
  const isPhone = deviceType === 'phone';

  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>, textareaValue: string) => {
      if (value === undefined) {
        setInnerValue(textareaValue);
      }

      if (onChangeValue) {
        onChangeValue(textareaValue);
      }
    },
    [onChangeValue, value],
  );

  return (
    <div className={s['wrapper']}>
      <Textarea
        hasCounter
        maxLength={POST_CONTENT_COMMENT_FORM_MAX_LENGTH}
        size={'M'}
        value={value || innerValue}
        disabled={isDisabled}
        onChange={handleChangeValue}
        minRows={3}
      />

      <div className={s['controls']}>
        <Button
          fullWidth={isPhone}
          disabled={(!value && !innerValue) || isDisabled}
          onClick={() => onSubmit(getDefinedValue(value, innerValue))}
        >
          Сохранить
        </Button>

        <Button theme={'fill_secondary'} fullWidth={isPhone} disabled={isDisabled} onClick={onCancel}>
          Отмена
        </Button>
      </div>
    </div>
  );
};
