import React, { memo } from 'react';
import { Route, Switch } from 'react-router';
import { AdfoxBannerMainPageFooter } from './AdfoxBannerMainPageFooter';
import { AdfoxBannerArticleFooter } from './AdfoxBannerArticleFooter';

export const AdfoxBannerFooter = memo(function AdfoxBannerFooter() {
  return (
    <Switch>
      <Route path={`/magazine/`} strict exact component={AdfoxBannerMainPageFooter} />
      <Route path={`/stati-:id/`} strict exact component={AdfoxBannerArticleFooter} />
    </Switch>
  );
});
