import { EffectCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TDependencyName, useSensitiveParams } from '../useSensitiveParams';
import { IEventTrackingData, useEventTracking } from '../useEventTracking';
import { useSearchParams } from '../useSearchParams';
import { useCurrentBreadCrumbs } from '../useCurrentBreadCrumbs';
import { getPostParents } from '../post';
import { selectPageMetaItems } from '../../selectors/pagesMeta';
import { formatDateToYYYYMMDD } from '../formatDate';
import { getPageExtraJournalArticleType } from '../analytics';
import { selectPost } from '../../selectors/post';
import { ERequestStatus } from '../../types/requestStatus';

interface IUseGetPostOptions {
  dependencyNames?: TDependencyName[];
  eventTrackerData?: IEventTrackingData;
}

/**
 * Выполняет callback для запроса поста
 */
export const useGetPost = (
  callback: EffectCallback,
  { dependencyNames = ['pathname'], eventTrackerData }: IUseGetPostOptions = {},
) => {
  const post = useSelector(selectPost);
  const pagesMeta = useSelector(selectPageMetaItems);
  const key = useSensitiveParams(dependencyNames);
  const breadCrumbs = useCurrentBreadCrumbs();
  const [prevKey, setPrevKey] = useState(key);
  const { trackPageView } = useEventTracking(eventTrackerData);
  const { page, commentId } = useSearchParams<{ page: number; commentId: number }>(['page', 'commentId']);

  /**
   * Трекает показ поста единожды
   * Необходимо дождаться формирования хлебных крошек (breadCrumbs),
   * иначе трек отправится дважды
   */
  useEffect(() => {
    if (post.status !== ERequestStatus.Succeed || !breadCrumbs.length) {
      return;
    }

    const {
      attributes: { rubrics, type, datePublish, author, advertising, category, tags },
    } = post;

    trackPageView({
      page: {
        extra: {
          ...(rubrics?.length ? { journalPageType: 'Article' } : {}),
          ...(rubrics?.length
            ? { journalRubrics: getPostParents({ rubrics, type, pagesMeta }).map(rubric => rubric.name) }
            : {}),
          publicationDate: datePublish && formatDateToYYYYMMDD(new Date(datePublish)),
          author: author || '',
          journalArticleType: getPageExtraJournalArticleType({
            category: category || '',
            advertising: Boolean(advertising),
            tags: tags || [],
          }),
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breadCrumbs, post.status, trackPageView]);

  /**
   * Запускает callback, если хотя бы соблюдено одно из условий:
   * - пост находится в статусе инициализации
   * - слепок значений из чувствительных ключей изменился
   * Трекает callback
   */
  useEffect(() => {
    if (post.status !== ERequestStatus.Initial && key === prevKey) {
      return;
    }

    callback();
    setPrevKey(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, post.status]);

  /**
   * Скролим наверх пока идет загрузка
   */
  useEffect(() => {
    if (post.status !== ERequestStatus.Loading || page > 1 || commentId) {
      return;
    }

    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post.status]);
};
