/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetPollModel,
  IGetPollRequest,
  IMappers,
  IGetPollResponse,
  IGetPollResponseError,
  TGetPollResponse,
} from './types';

const defaultConfig: TGetPollModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'journal',
  pathApi: '/v1/get-poll/',
  hostType: 'api',
} as TGetPollModel;

function createGetPollModel(parameters: IGetPollRequest): TGetPollModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetPollOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetPollRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetPoll<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetPollOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetPollResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetPollModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetPollResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetPollResponseError);
    }
  }

  return { response, statusCode } as TGetPollResponse;
}

export { defaultConfig, createGetPollModel, fetchGetPoll };
