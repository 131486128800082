import React, { FC } from 'react';
import { useDeviceType } from '@cian/ui-kit';
import { AdfoxBannerArticleFooterMobile } from './AdfoxBannerArticleFooterMobile';
import { AdfoxBannerArticleFooterDesktop } from './AdfoxBannerArticleFooterDesktop';

export const AdfoxBannerArticleFooter: FC = () => {
  const deviceType = useDeviceType();

  return deviceType === 'desktop' ? <AdfoxBannerArticleFooterDesktop /> : <AdfoxBannerArticleFooterMobile />;
};
