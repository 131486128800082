import { ERequestStatus } from './requestStatus';
import { IFastFiltersUserSchema } from '../repositories/monolith-python/entities/schemas/FastFiltersUserSchema';
import { IUserDataSchema } from '../repositories/journal/entities/user/UserDataSchema';
import { IUserGaData } from './analytics';

/**
 * @deprecated
 */
export interface IUserAction {
  type: string;
  payload: {
    data: IUser;
  };
}

/**
 * @deprecated
 */
export interface IUser {
  avatar: string;
  email: string;
  isBlock: boolean;
  fullName: string;
  isProfi: boolean;
  companyName?: string;
  linkUserProfiDescription: string;
  linkUserTrustDescription: string;
  blogsCount?: number;
  rating?: number;
  permissions: {
    canModerateAnnouncements: boolean;
    canModerateAnnouncementsExpert: boolean;
    canModerateUsers: boolean;
    canViewAnnouncements: boolean;
    canViewUsers: boolean;
  };
  roles: string[];
  userId: number;
  userIdentifications: boolean;
  userTrust: boolean;
  specialistLink?: string;
  isEmailConfirmed?: boolean;
}

export enum EUserRole {
  Admin = 'admin_jornal',
  Moderator = 'mod_jornal',
}

export enum EUserLkType {
  /** Собственник */
  Homeowner = 'homeowner',
  /** Поискун */
  Searcher = 'searcher',
  /** Агент */
  Specialist = 'specialist',
}

export interface IUserCommon extends IUserDataSchema {
  /** Тип пользователя в ЛК */
  lkType: EUserLkType | null;
  /** Включен ли режим b2b */
  isB2BModeEnabled: boolean;
  /** Показано ли запрещающее сообщение при переключении B2B режима */
  isB2BForbiddingMessageShown: boolean;
  /** Данные для аналитики */
  userGaData: IUserGaData;
}

interface INonAuthenticatedUser {
  /** Авторизован ли пользователь */
  isAuthenticated: false;
}

export interface IAuthenticatedUser extends IFastFiltersUserSchema {
  /** Авторизован ли пользователь */
  isAuthenticated: true;
}

export interface IUserState {
  /** Состояние запроса */
  status: ERequestStatus;
}

export type TUser = INonAuthenticatedUser | IAuthenticatedUser;

export type TUserState = IUserState & IUserCommon & TUser;
