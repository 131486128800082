import { IParsePhotoGalleries, IParsePhotoGalleryItem, IParsePhotoGallerySlideItem } from './parsePhotoGalleriesNew';

/**
 * Парсит и вырезает настройки для фотогалерей
 */
export const parsePhotoGalleries = (
  html: string,
  replaceCallback?: (item: IParsePhotoGalleryItem, index: number) => string,
): IParsePhotoGalleries => {
  const pattern = /<div[^>]*?data-name="photo-gallery"[^>]*?>[^<]*?<\/div>/gis;
  const items: IParsePhotoGalleryItem[] = [];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  let index = 0;
  while ((match = pattern.exec(html))) {
    index = html.indexOf(match[0], index);
    const outerHTML = match[0];

    if (outerHTML) {
      let parsedSlides: IParsePhotoGallerySlideItem[] | null;

      try {
        parsedSlides = JSON.parse((outerHTML.match(/data-slides="([^"]*?)"/)?.[1] || '').replace(/&quot;/g, '"'));
      } catch (e) {
        parsedSlides = null;
      }

      items.push({
        slides: parsedSlides?.length ? parsedSlides : [],
        source: outerHTML.match(/data-source="([^"]*?)"/)?.[1],
        description: outerHTML.match(/data-description="([^"]*?)"/)?.[1],
        objectFit: outerHTML.match(/data-object-fit="([^"]*?)"/)?.[1],
        id: `photo-gallery-${items.length + 1}`,
        outerHtml: outerHTML,
        position: index / html.length < 0.333 ? 'top' : index / html.length < 0.667 ? 'middle' : 'bottom',
      });
    }
  }

  return {
    html: replaceCallback
      ? items.reduce((acc, item, index) => acc.replace(item.outerHtml, replaceCallback(item, index)), html)
      : html,
    items,
  };
};
