import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PostPollLayout } from '../../components/PostPollLayout';
import { PostPoll } from '../../components/PostPoll';
import { getPostPoll, resetPostPoll, votePostPoll } from '../../actions/postPoll';
import { selectPost } from '../../selectors/post';
import { selectPostPoll } from '../../selectors/postPoll';
import { parsePostCardPath } from '../../utils';
import { TThunkDispatch } from '../../types/redux';
import { ERequestStatus } from '../../types/requestStatus';
import { EType } from '../../repositories/journal/entities/journal/JournalAttributesSchema';
import { EStatus } from '../../repositories/journal/v1/get-poll';

export const PostPollContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const poll = useSelector(selectPostPoll);
  const post = useSelector(selectPost);
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const { id: qsPostId } = parsePostCardPath(pathname);

  /**
   * Сбрасывает опрос (если есть) при переходе с поста на пост
   */
  useEffect(() => {
    if (poll.status === ERequestStatus.Succeed && pathname !== prevPathname) {
      dispatch(resetPostPoll());
    }
  }, [dispatch, pathname, poll.status, prevPathname]);

  /**
   * Запрашивает опрос для типа поста Статья, если еще ни разу не запрашивали
   */
  useEffect(() => {
    if (
      (poll.status !== ERequestStatus.Initial && pathname === prevPathname) ||
      !('id' in post) ||
      post.id !== qsPostId ||
      post.type !== EType.Articles
    ) {
      return;
    }

    void dispatch(getPostPoll({ postId: post.id }));
    setPrevPathname(pathname);
  }, [dispatch, pathname, poll.status, post, prevPathname, qsPostId]);

  /**
   * Сбрасывает опрос (если есть) при размонтировании
   */
  useEffect(() => {
    return () => {
      if (poll.status === ERequestStatus.Succeed) {
        dispatch(resetPostPoll());
      }
    };
  }, [dispatch, poll.status]);

  /**
   * Отправляет результаты опроса
   */
  const handleSubmit = useCallback(
    async (answerIds: number[]) => {
      if (!('id' in post) || !('id' in poll)) {
        return false;
      }

      return dispatch(votePostPoll({ postId: post.id, pollId: poll.id, votes: answerIds }));
    },
    [dispatch, poll, post],
  );

  if (
    !('id' in poll) ||
    poll.status !== ERequestStatus.Succeed ||
    poll.pollStatus === EStatus.Hidden ||
    !poll.answers.length ||
    new Date() < new Date(poll.dateStart)
  ) {
    return null;
  }

  return (
    <PostPollLayout>
      <PostPoll {...poll} onSubmit={handleSubmit} />
    </PostPollLayout>
  );
};
