import * as React from 'react';
import { useSelector } from 'react-redux';

import { AdvertisingLabel } from '../../components/AdvertisingLabel';
import { selectPost } from '../../selectors/post';

/**
 * Контейнер с плашкой "Партнерский материал"
 */
export const PostAdvertisingLabelContainer = () => {
  const post = useSelector(selectPost);

  if (!('id' in post)) {
    return null;
  }

  const {
    attributes: { advertising },
  } = post;

  if (!advertising) {
    return null;
  }

  return <AdvertisingLabel />;
};
