import { IPostContentPhotoGalleries } from '../../types/postContentPhotoGalleries';
import {
  EPostContentPhotoGalleryActionType,
  TPostContentPhotoGalleryActions,
} from '../../actions/postContentPhotoGallery';
import { ERequestStatus } from '../../types/requestStatus';

export const defaultState: IPostContentPhotoGalleries = {
  items: [],
};

export const postContentPhotoGalleriesReducer = (state = defaultState, action: TPostContentPhotoGalleryActions) => {
  switch (action.type) {
    case EPostContentPhotoGalleryActionType.Set: {
      return {
        ...state,
        items: [...state.items, { ...action.payload, status: ERequestStatus.Succeed }],
      };
    }

    case EPostContentPhotoGalleryActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};
