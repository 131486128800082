import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hydrate } from 'react-dom';
import { useLocation } from 'react-router-dom';

import { PostContentRecommendedOffersSlider } from '../../components/PostContentRecommendedOffersSlider';
import { IParseRecommendedOffersItem, parseRecommendedOffers } from './parseRecommendedOffers';
import { selectPostContentRecommendedOfferGroups } from '../../selectors/postContentRecommendedOffers';
import { getPostContentOffers, getPostContentOffersReset } from '../../actions/postContentRecommendedOffers';
import { useEventTracking } from '../useEventTracking';
import {
  POST_CONTENT_RECOMMENDED_OFFERS_ITEM_HEIGHT,
  POST_CONTENT_RECOMMENDED_OFFERS_ITEM_WIDTH,
  POST_CONTENT_RECOMMENDED_OFFERS_REQUEST_LIMIT,
  POST_CONTENT_RECOMMENDED_OFFERS_SCROLL_LENGTH,
} from '../../constants';
import { TThunkDispatch } from '../../types/redux';
import { EOfferCategories } from '../../repositories/journal/v1/get-recommendations';
import { ERequestStatus } from '../../types/requestStatus';
import { IRecommendationOfferSchema } from '../../repositories/journal/entities/recommendations/RecommendationOfferSchema';
import { IPostContentRecommendedOffers } from '../../types/postContentRecommendedOffers';

interface IUseContentParams {
  canParse: boolean;
  canRender: boolean;
  postContent: string;
  setPostContent: Dispatch<SetStateAction<string>>;
  done(): void;
}

export interface IHandleClickParams extends Pick<IRecommendationOfferSchema, 'offerId' | 'url' | 'fromDeveloper'> {
  index: number;
  position: IPostContentRecommendedOffers['position'];
}

/**
 * Рендерит и управляет группами рекомендованных объявлений в контенте поста
 */
export const useContentRecommendedOffersNew = ({
  canParse,
  canRender,
  postContent,
  setPostContent,
  done,
}: IUseContentParams) => {
  const dispatch = useDispatch<TThunkDispatch>();
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const [isParsed, setIsParsed] = useState(false);
  const [parsedGroupsParams, setParsedGroupsParams] = useState<IParseRecommendedOffersItem[]>([]);
  const groups = useSelector(selectPostContentRecommendedOfferGroups);
  const { trackEvent } = useEventTracking();

  /** Сбрасывает оферы при клиентском переходе с поста на пост */
  useEffect(() => {
    if (pathname !== prevPathname) {
      dispatch(getPostContentOffersReset());
      setPrevPathname(pathname);
      setIsParsed(false);
    }
  }, [dispatch, pathname, prevPathname]);

  /** Трекает клик по рекомендованному оферу */
  const handleClick = useCallback(
    ({ offerId, url, index, position, fromDeveloper }: IHandleClickParams) => {
      trackEvent({
        category: 'Magazine',
        action: `to_card_${position}_recommendations`,
        label: `${url}from_developer=${Number(fromDeveloper)}`,
        page: {
          extra: {
            type: index + 1,
          },
        },
        product: { id: offerId },
      });
    },
    [trackEvent],
  );

  /** Рендерит слайдеры оферов */
  useEffect(() => {
    if (!groups.length || !canRender) {
      return;
    }

    groups.forEach(group => {
      if (group.status !== ERequestStatus.Succeed) {
        return;
      }

      const root = document.getElementById(group.id);

      if (root) {
        hydrate(
          <PostContentRecommendedOffersSlider
            group={group}
            offerWidth={POST_CONTENT_RECOMMENDED_OFFERS_ITEM_WIDTH}
            offerHeight={POST_CONTENT_RECOMMENDED_OFFERS_ITEM_HEIGHT}
            scrollLength={POST_CONTENT_RECOMMENDED_OFFERS_SCROLL_LENGTH}
            onOfferClick={({ offerId, url, fromDeveloper }, index) =>
              handleClick({ offerId, url, index, position: group.position, fromDeveloper })
            }
          />,
          root,
        );
      }
    });
  }, [canRender, groups, handleClick]);

  /** Парсит параметры рекомендованных оферов из контента */
  useEffect(() => {
    if (!canParse || isParsed) {
      return;
    }

    if (groups.length) {
      setIsParsed(true);
      done();

      return;
    }

    const { items, html } = parseRecommendedOffers(postContent, ({ id }) => `<div id="${id}"></div>`);

    if (items.length) {
      setPostContent(html);
      setParsedGroupsParams(items);
    }

    setIsParsed(true);
    done();
  }, [canParse, done, groups.length, isParsed, postContent, setPostContent]);

  /** Запрашивает рекомендованные оферы/сохраняет в стор */
  useEffect(() => {
    if (!parsedGroupsParams.length) {
      return;
    }

    parsedGroupsParams.map(async ({ geoId, maxPrice, minPrice, offerCategories, rooms, id, title, url, position }) => {
      await dispatch(
        getPostContentOffers({
          id,
          title,
          url,
          position,
          geoId: geoId ? parseInt(geoId, 10) : undefined,
          maxPrice: maxPrice ? parseInt(maxPrice, 10) : undefined,
          minPrice: minPrice ? parseInt(minPrice, 10) : undefined,
          offerCategories: (offerCategories as EOfferCategories[]) || undefined,
          rooms: rooms ? rooms.map(room => parseInt(room, 10)) : undefined,
          limit: POST_CONTENT_RECOMMENDED_OFFERS_REQUEST_LIMIT,
        }),
      );
    });

    setParsedGroupsParams([]);
  }, [dispatch, parsedGroupsParams]);

  /** При размонтировании сбрасывает рекомендованные оферы */
  useEffect(() => {
    return () => {
      dispatch(getPostContentOffersReset());
    };
  }, [dispatch]);
};
