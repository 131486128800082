import { IPostContentBlockquotes } from '../../types/postContentBlockquotes';
import { EPostContentBlockquoteActionType, TPostContentBlockquoteActions } from '../../actions/postContentBlockquote';
import { ERequestStatus } from '../../types/requestStatus';

export const defaultState: IPostContentBlockquotes = {
  items: [],
};

export const postContentBlockquotesReducer = (state = defaultState, action: TPostContentBlockquoteActions) => {
  switch (action.type) {
    case EPostContentBlockquoteActionType.Set: {
      return {
        ...state,
        items: [...state.items, { ...action.payload, status: ERequestStatus.Succeed }],
      };
    }

    case EPostContentBlockquoteActionType.Reset:
      return defaultState;

    default:
      return state;
  }
};
