import React, { useCallback, useState } from 'react';
import { Checkbox } from '@cian/ui-kit';

import { ListItemContent } from '../ListItemContent';
import { ListItem } from '../ListItem';
import { IPollInfoAnswerSchema } from '../../../../repositories/journal/entities/poll/PollInfoAnswerSchema';
import * as s from './CheckboxListItem.css';

interface IListItem extends IPollInfoAnswerSchema {
  pollId: number;
  isDisabled?: boolean;
  onChange(isChecked: boolean): void;
}

/**
 * Элемент списка с чекбоксом
 */
export const CheckboxListItem = ({ image, name, isDisabled, onChange }: IListItem) => {
  const [isChecked, setIsChecked] = useState(false);
  const style = isChecked ? { background: 'var(--solid-red60)' } : {};

  const handleChange = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onChange(!isChecked);
      setIsChecked(!isChecked);
    },
    [isChecked, onChange],
  );

  return (
    <ListItem onClick={!isDisabled ? handleChange : undefined} style={style}>
      <ListItemContent image={image} name={name}>
        <div className={s['checkbox']}>
          <Checkbox disabled={isDisabled} checked={isChecked} />
        </div>
      </ListItemContent>
    </ListItem>
  );
};
