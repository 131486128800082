import { ITypedReduxAction } from '../../../types/redux/actionType';
import { IPollVoteResponse } from '../../../repositories/journal/v1/poll-vote';

export enum EVotePostPollActionType {
  Succeed = 'postPoll/votePostPoll/succeed',
}

export interface IVotePostPollSucceedParams {
  vote: IPollVoteResponse['vote'];
}

export type TVotePostPollSucceed = ITypedReduxAction<EVotePostPollActionType.Succeed, IVotePostPollSucceedParams>;

export type TVotePostPoll = TVotePostPollSucceed;
