interface IGetPageExtraJournalArticleType {
  category?: string;
  advertising?: boolean;
  tags?: string[];
}

/**
 * Возвращает тип поста для отправки Циан-аналитики
 */
export const getPageExtraJournalArticleType = ({ category, advertising, tags }: IGetPageExtraJournalArticleType) => {
  // Явная метка при редактировании статьи - это рекламная статья
  if (advertising) {
    return 'advertising';
  }

  // Наличие тега "на правах рекламы" - это рекламная статья
  if (tags?.length && tags.map(tag => tag.toLowerCase()).includes('на правах рекламы')) {
    return 'advertising';
  }

  // Наличие тега "партнерские проекты" - это рекламная статья
  if (tags?.length && tags.map(tag => tag.toLowerCase()).includes('партнерские проекты')) {
    return 'advertising';
  }

  // Одноименные категории отправляем как есть
  if (category && ['interview', 'analytics', 'seo'].includes(category)) {
    return category;
  }

  return 'other';
};
