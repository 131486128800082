import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { UIHeading4 } from '@cian/ui-kit';

import { IPostParentItem } from '../../utils';
import * as s from './PostParents.css';

export interface IPostParents {
  items: IPostParentItem[];
}

/**
 * Список ссылок на родителей поста: категории или рубрики
 */
export const PostParents = ({ items }: IPostParents) => {
  return (
    <div className={s['wrapper']}>
      {items.map(({ name, pathname }, index) => (
        <Fragment key={index}>
          <Link to={pathname} className={s['link']} title={`Перейти в раздел '${name}'`}>
            <UIHeading4 color={'primary_100'}>{name}</UIHeading4>
          </Link>

          {index < items.length - 1 && <UIHeading4 color={'primary_100'}>·</UIHeading4>}
        </Fragment>
      ))}
    </div>
  );
};
