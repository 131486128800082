export interface IParseCollapsersItem {
  /** Заголовок */
  title: string;
  /** Полное HTML-тело iframe */
  content: string;
  /** Id коллапсера */
  id: string;
  /** Полное HTML-тело шаблона adfox */
  outerHtml: string;
}

export interface IParseCollapsers {
  /** HTML-контент */
  html: string;
  /** Коллекция параметров коллапсера */
  items: IParseCollapsersItem[];
}

/**
 * 1. Обрамляет collapser div'ом c id
 * 2. Вырезает данные коллапсера
 */
export const parseCollapsers = (
  html: string,
  replaceCallback?: (item: IParseCollapsersItem, index: number) => string,
): IParseCollapsers => {
  const pattern =
    /<div[^>]*?(?:class="collapse")>[^<]*?<div[^>]*?(?:class="collapse__title")>(.*?)<\/div>[^<]*?<div[^>]*?(?:class="collapse__content")>(.*?)<\/div>[^<]*?<\/div>/gis;
  const items = [] as IParseCollapsersItem[];
  let match;

  if (!html) {
    return {
      html: '',
      items,
    };
  }

  while ((match = pattern.exec(html))) {
    items.push({
      outerHtml: match[0],
      title: match[1],
      content: match[2],
      id: `collapser-${items.length + 1}`,
    });
  }

  return {
    html: replaceCallback
      ? items.reduce((acc, item, index) => acc.replace(item.outerHtml, replaceCallback(item, index)), html)
      : html,
    items,
  };
};
