import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useValidTag } from '../useValidTag';
import { prepareQSParam } from '../prepareQSParam';
import { getBreadCrumbs } from '../getBreadCrumbs';
import { selectPost } from '../../selectors/post';
import { selectCurrentPageMeta, selectPageMetaItems } from '../../selectors/pagesMeta';
import {
  QS_SEARCH_SUPPORTING_PAGE_PATHS,
  QS_TAG_SUPPORTING_PAGE_PATHS,
  QS_WITH_TAG_SUPPORTING_PAGE_PATHS,
} from '../../constants';
import { IApplicationState } from '../../types/redux';

/**
 * Формирует актуальные хлебные крошки для разных типов страниц
 */
export const useCurrentBreadCrumbs = () => {
  const { search, pathname } = useLocation();
  const pagesMeta = useSelector(selectPageMetaItems);
  const currentPageMeta = useSelector((state: IApplicationState) => selectCurrentPageMeta(state, pathname));
  const withTag = useValidTag('with-tag');
  const post = useSelector(selectPost);
  const currentBreadCrumbs = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return getBreadCrumbs({
      currentPageMeta,
      pagesMeta,
      post: 'id' in post ? post : undefined,
      withTag: (QS_WITH_TAG_SUPPORTING_PAGE_PATHS.includes(pathname) && withTag) || undefined,
      tag: (QS_TAG_SUPPORTING_PAGE_PATHS.includes(pathname) && prepareQSParam(searchParams.get('tag'))) || undefined,
      search:
        (QS_SEARCH_SUPPORTING_PAGE_PATHS.includes(pathname) && prepareQSParam(searchParams.get('search'))) || undefined,
    });
  }, [currentPageMeta, pagesMeta, pathname, post, search, withTag]);

  const [breadCrumbs, setBreadCrumbs] = useState(currentBreadCrumbs);

  useEffect(() => {
    if (JSON.stringify(currentBreadCrumbs) === JSON.stringify(breadCrumbs)) {
      return;
    }

    setBreadCrumbs(currentBreadCrumbs);
  }, [breadCrumbs, currentBreadCrumbs]);

  return breadCrumbs;
};
