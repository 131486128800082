import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  parsePostCardPath,
  useContentScriptsNew,
  useContentBeforeAfterGalleriesNew,
  useContentRecommendedOffersNew,
  useContentCollapsersNew,
  useContentAdfoxBannersNew,
  useContentYoutubeVideosNew,
  useContentJournalBannersNew,
  useContentBlockquotesNew,
  useContentPhotoGalleriesNew,
} from '../../utils';
import { selectPost } from '../../selectors/post';
import { setPostAttribute } from '../../actions/post';
import { ERequestStatus } from '../../types/requestStatus';

/**
 * Рендерит вставки в контент поста
 */
export const usePreparedContent = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const [step, setStep] = useState(0);
  const post = useSelector(selectPost);
  const { id: qsPostId } = parsePostCardPath(pathname);
  const [canRender, setCanRender] = useState(false);
  const [postContent, setPostContent] = useState('');

  /** Сбрасывает состояния при переходе с поста на пост */
  useEffect(() => {
    if (pathname === prevPathname) {
      return;
    }

    setStep(0);
    setPrevPathname(pathname);
    setCanRender(false);
    setPostContent('');
  }, [pathname, prevPathname]);

  /** Запускает парсинг, когда пост загружен */
  useEffect(() => {
    if (!('id' in post) || post.id !== qsPostId || post.status !== ERequestStatus.Succeed || step) {
      return;
    }

    setStep(1);
    setPostContent(post.attributes.preparedContent || post.attributes.content || '');
  }, [post, qsPostId, step]);

  /** Запускает рендеринг, когда пройдены все этапы парсинга */
  useEffect(() => {
    if (step <= 9) {
      return;
    }

    setCanRender(true);
  }, [step]);

  /** Сохраняет в стор изменения в контексте поста */
  useEffect(() => {
    if (!postContent) {
      return;
    }

    dispatch(setPostAttribute({ key: 'preparedContent', value: postContent }));
  }, [dispatch, postContent]);

  /** Переключает на следующий этап */
  const nextStep = useCallback(() => {
    setStep(step => step + 1);
  }, []);

  useContentScriptsNew({ canParse: step === 1, canRender, postContent, setPostContent, done: nextStep });
  useContentRecommendedOffersNew({ canParse: step === 2, canRender, postContent, setPostContent, done: nextStep });
  useContentCollapsersNew({ canParse: step === 3, canRender, postContent, setPostContent, done: nextStep });
  useContentBeforeAfterGalleriesNew({ canParse: step === 4, canRender, postContent, setPostContent, done: nextStep });
  useContentAdfoxBannersNew({ canParse: step === 5, canRender, postContent, setPostContent, done: nextStep });
  useContentYoutubeVideosNew({ canParse: step === 6, canRender, postContent, setPostContent, done: nextStep });
  useContentJournalBannersNew({ canParse: step === 7, canRender, postContent, setPostContent, done: nextStep });
  useContentBlockquotesNew({ canParse: step === 8, canRender, postContent, setPostContent, done: nextStep });
  useContentPhotoGalleriesNew({ canParse: step === 9, canRender, postContent, setPostContent, done: nextStep });
};
