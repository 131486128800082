import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { hydrate } from 'react-dom';
import { useLocation } from 'react-router-dom';

import { CollapserUncontrolled } from '../../components/Collapser';
import { PostContentCollapserLayout } from '../../components/PostContentCollapserLayout';
import { sanitizePostContent } from '../html';
import { useEventTracking } from '../useEventTracking';
import { IParseCollapsersItem, parseCollapsers } from './parseCollapsers';

interface IUseContentParams {
  canParse: boolean;
  canRender: boolean;
  postContent: string;
  setPostContent: Dispatch<SetStateAction<string>>;
  done(): void;
}

/**
 * Рендерит и управляет коллапсерами в контенте поста
 */
export const useContentCollapsersNew = ({
  canParse,
  canRender,
  postContent,
  setPostContent,
  done,
}: IUseContentParams) => {
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const [isParsed, setIsParsed] = useState(false);
  const [parsedParams, setParsedParams] = useState<IParseCollapsersItem[]>([]);
  const { trackEvent } = useEventTracking();

  /** Сбрасывает стэйт при клиентском переходе с поста на пост */
  useEffect(() => {
    if (pathname !== prevPathname) {
      setPrevPathname(pathname);
      setIsParsed(false);
      setParsedParams([]);
    }
  }, [pathname, prevPathname]);

  /** Трекает событие раскрытия коллапсера */
  const handleToggle = useCallback(() => {
    trackEvent({
      category: 'Magazine',
      action: 'click_collapser',
      label: '',
    });
  }, [trackEvent]);

  /** Рендерит коллапсеры */
  useEffect(() => {
    if (!parsedParams.length || !canRender) {
      return;
    }

    parsedParams.forEach(({ id, title, content }) => {
      const root = document.getElementById(id);

      if (root) {
        hydrate(
          <PostContentCollapserLayout>
            <CollapserUncontrolled
              /* eslint-disable-next-line react/no-danger */
              title={<div dangerouslySetInnerHTML={{ __html: sanitizePostContent(title) }} />}
              onToggle={handleToggle}
            >
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: sanitizePostContent(content) }} />
            </CollapserUncontrolled>
          </PostContentCollapserLayout>,
          root,
        );
      }
    });
  }, [canRender, handleToggle, parsedParams]);

  /** Парсит параметры коллапсеров */
  useEffect(() => {
    if (!canParse || isParsed) {
      return;
    }

    const { items, html } = parseCollapsers(postContent, ({ id }) => `<div id="${id}"></div>`);

    if (items.length) {
      setPostContent(html);
      setParsedParams(items);
    }

    setIsParsed(true);
    done();
  }, [canParse, done, isParsed, postContent, setPostContent]);
};
