import { StatisticsListItem } from '../../components/StatisticsListItem';
import { CheckboxListItem } from '../../components/CheckboxListItem';
import { RadioListItem } from '../../components/RadioListItem';
import { EStatus } from '../../../../repositories/journal/v1/get-poll';
import { IPostPoll } from '../../../../types/postPoll';

interface IGetListItemType extends Pick<IPostPoll, 'isMultiAnswer' | 'dateEnd' | 'pollStatus'> {
  isUserVoted: boolean;
}

/** Возвращает компонент элемента списка Опроса */
export const getListItemComponent = ({ pollStatus, dateEnd, isMultiAnswer, isUserVoted }: IGetListItemType) => {
  if (pollStatus !== EStatus.Active || (dateEnd && new Date(dateEnd) <= new Date()) || isUserVoted) {
    return { component: StatisticsListItem, name: 'StatisticsListItem' };
  }

  if (isMultiAnswer) {
    return { component: CheckboxListItem, name: 'CheckboxListItem' };
  }

  return { component: RadioListItem, name: 'RadioListItem' };
};
