import {
  IHttpApi,
  mergeBaseUri,
  mergePaths,
  transformObjectToQuery,
  IHTTPRequestConfig,
  IHTTPCompletedResponse,
} from '@cian/http-api';

import { IUpdateQuestionPostRequest, IUpdateQuestionPostConfig, TResponses } from './types';

const defaultConfig: Required<IUpdateQuestionPostConfig> = {
  pathApi: '/v1/edit-question',
  browserUrl: 'http://www.master.dev3.cian.ru/content-api',
};

export const defaultRequest: IHTTPRequestConfig = {
  uri: {
    host: '',
    path: '',
  },
  method: 'POST',
  requestType: 'formData',
  body: '',
  withCredentials: true,
  responseType: 'json',
  headers: [['Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8']],
};

function createUpdateQuestionPostRequest({
  bodyParameters,
  queryParameters,
  config,
}: Pick<IEditCommentOptions, 'bodyParameters' | 'queryParameters' | 'config'>): IHTTPRequestConfig {
  const { pathApi, browserUrl } = { ...defaultConfig, ...config };

  return {
    ...defaultRequest,
    uri: mergeBaseUri(browserUrl, {
      path: (path: string) => mergePaths(path, pathApi),
      query: transformObjectToQuery(queryParameters),
    }),
    body: transformObjectToQuery(bodyParameters),
  };
}

export interface IEditCommentOptions {
  httpApi: IHttpApi;
  bodyParameters: IUpdateQuestionPostRequest;
  queryParameters?: IUpdateQuestionPostRequest;
  config?: IUpdateQuestionPostConfig;
}

async function requestUpdateQuestionPost({
  httpApi,
  bodyParameters,
  queryParameters,
  config,
}: IEditCommentOptions): Promise<IHTTPCompletedResponse<TResponses>> {
  return httpApi.httpRequest(createUpdateQuestionPostRequest({ bodyParameters, queryParameters, config }));
}

export { defaultConfig, createUpdateQuestionPostRequest, requestUpdateQuestionPost };
