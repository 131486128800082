import { actionGenerator } from '../../utils/redux/actionGenerator';
import { TPostContentBeforeAfterGallerySet, EPostContentBeforeAfterGalleryActionType } from './types';
import { IPostContentBeforeAfterGallery } from '../../types/postContentBeforeAfterGalleries';

export const postContentBeforeAfterGallerySet: (
  data: IPostContentBeforeAfterGallery,
) => TPostContentBeforeAfterGallerySet = actionGenerator<
  EPostContentBeforeAfterGalleryActionType.Set,
  IPostContentBeforeAfterGallery
>(EPostContentBeforeAfterGalleryActionType.Set);
