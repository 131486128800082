import React, { ChangeEvent, useCallback, useState } from 'react';
import { Button, Outside } from '@cian/ui-kit';

import { Textarea } from '../../Textarea';
import { PostCommentsNotifySwitcher } from '../../PostCommentsNotifySwitcher';
import { getDefinedValue } from '../utils/getDefinedValue';
import { POST_CONTENT_COMMENT_FORM_MAX_LENGTH } from '../../../constants';
import * as s from './AddCommentForm.css';

export interface IAddCommentForm {
  onSubmit(value: string, isChecked: boolean): void;
  value?: string;
  isSubscriptionChecked?: boolean;
  isExpanded?: boolean;
  isDisabled?: boolean;
  onChangeValue?(value: string): void;
  onChangeSubscriptionChecked?(value: boolean): void;
  onToggleExpanded?(value: boolean): void;
}

/**
 * Форма для отправки комментария
 */
export const AddCommentForm = ({
  onSubmit,
  value,
  isSubscriptionChecked,
  isExpanded,
  isDisabled,
  onChangeValue,
  onChangeSubscriptionChecked,
  onToggleExpanded,
}: IAddCommentForm) => {
  const [isInnerExpanded, setIsInnerExpanded] = useState(isExpanded === true);
  const [innerValue, setInnerValue] = useState('');
  const [isChecked, setIsChecked] = useState(isSubscriptionChecked === true);

  const toggleIsExpanded = useCallback(
    (value: boolean) => {
      if (isExpanded === undefined) {
        setIsInnerExpanded(value);
      }

      if (onToggleExpanded) {
        onToggleExpanded(value);
      }
    },
    [isExpanded, onToggleExpanded],
  );

  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>, textareaValue: string) => {
      if (value === undefined) {
        setInnerValue(textareaValue);
      }

      if (onChangeValue) {
        onChangeValue(textareaValue);
      }
    },
    [onChangeValue, value],
  );

  const handleChangeIsChecked = useCallback(
    (e: ChangeEvent<HTMLInputElement>, value: boolean) => {
      if (isChecked === undefined) {
        setIsChecked(value);
      }

      if (onChangeSubscriptionChecked) {
        onChangeSubscriptionChecked(value);
      }
    },
    [isChecked, onChangeSubscriptionChecked],
  );

  return (
    <Outside
      active={getDefinedValue(isExpanded, isInnerExpanded)}
      onOutside={() => toggleIsExpanded(false)}
      insideRefs={[]}
    >
      <div className={s['wrapper']}>
        <Textarea
          hasCounter={getDefinedValue(isExpanded, isInnerExpanded)}
          placeholder="Прокомментировать"
          maxLength={POST_CONTENT_COMMENT_FORM_MAX_LENGTH}
          size={'M'}
          value={value || innerValue}
          disabled={isDisabled}
          onChange={handleChangeValue}
          onFocus={() => {
            toggleIsExpanded(true);
          }}
          minRows={getDefinedValue(isExpanded, isInnerExpanded) ? 3 : undefined}
          maxRows={getDefinedValue(isExpanded, isInnerExpanded) ? undefined : 1}
        />

        <div className={`${s['controls']} ${getDefinedValue(isExpanded, isInnerExpanded) ? s['_shown'] : ''}`}>
          <div className={s['controls-wrapper']}>
            <Button
              onClick={() =>
                onSubmit(getDefinedValue(value, innerValue), getDefinedValue(isSubscriptionChecked, isChecked))
              }
              disabled={(!value && !innerValue) || isDisabled}
            >
              Опубликовать
            </Button>

            <PostCommentsNotifySwitcher
              isChecked={getDefinedValue(isSubscriptionChecked, isChecked)}
              isDisabled={isDisabled}
              onChange={handleChangeIsChecked}
            />
          </div>
        </div>
      </div>
    </Outside>
  );
};
