import { ITypedReduxAction } from '../../types/redux/actionType';
import { IPostContentBlockquote } from '../../types/postContentBlockquotes';

export enum EPostContentBlockquoteActionType {
  Set = 'postContentBlockquote/set',
  Reset = 'postContentBlockquote/reset',
}

export type TPostContentBlockquoteSet = ITypedReduxAction<EPostContentBlockquoteActionType.Set, IPostContentBlockquote>;

export type TPostContentBlockquoteReset = ITypedReduxAction<EPostContentBlockquoteActionType.Reset>;

export type TPostContentBlockquoteActions = TPostContentBlockquoteSet | TPostContentBlockquoteReset;
