import { IHTTPCompletedResponse } from '@cian/http-api';

import { TThunkAction } from '../../../types/redux';
import {
  requestUpdateBlogPost,
  IUpdateBlogPostRequest,
  TResponses,
  IUpdateBlogPostResponse,
} from '../../../services/postForm/updateBlogPost';
import { throwError } from '../../../utils';
import { setPostAttribute } from '../setPostAttribute';
import { EStatus } from '../../../repositories/journal/entities/journal/JournalAttributesSchema';

export const updateBlogPostStatus = ({
  id,
}: Required<Pick<IUpdateBlogPostRequest, 'id'>>): TThunkAction<Promise<boolean>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger, config } = context;

    try {
      const { responseBody: response, responseStatusCode: statusCode }: IHTTPCompletedResponse<TResponses> =
        await requestUpdateBlogPost({
          httpApi,
          bodyParameters: {
            status: EStatus.Unchecked,
          },
          queryParameters: {
            id,
          },
          config: {
            browserUrl: config.get<string>('backend-content.browserUrl') || '',
          },
        });

      if (statusCode !== 200) {
        throwError({ message: 'Не удалось отредактировать пост', statusCode, domain: 'actions.updateBlogPostStatus' });

        return false;
      }

      dispatch(
        setPostAttribute({
          key: 'status',
          value: (response as IUpdateBlogPostResponse)?.data?.attributes?.status as EStatus,
        }),
      );

      return true;
    } catch (e) {
      logger.error(e, {
        domain: 'actions.updateBlogPostStatus',
      });

      return false;
    }
  };
};
