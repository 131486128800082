import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { hydrate } from 'react-dom';

import { IParsePhotoGalleryItem } from './parsePhotoGalleriesNew';
import { PostPhotoGallery } from '../../components/PostPhotoGallery';

interface IUseContentPhotoGalleries {
  html: string;
  galleries: IParsePhotoGalleryItem[];
}

/**
 * Рендерит и управляет галереями Было/Стало
 */
export const useContentPhotoGalleries = ({ html, galleries }: IUseContentPhotoGalleries): string => {
  /** Рендерит для CSR */
  useEffect(() => {
    galleries.forEach(({ slides, source, description, objectFit, id }: IParsePhotoGalleryItem) => {
      if (slides?.length) {
        hydrate(
          <PostPhotoGallery slides={slides} source={source} description={description} objectFit={objectFit} />,
          document.getElementById(id),
        );
      }
    });
  }, [galleries]);

  /** Рендерит для SSR */
  if (typeof window === 'undefined') {
    return galleries.reduce((acc, { slides, source, description, objectFit, id }) => {
      if (!slides?.length) {
        return acc;
      }

      return acc.replace(
        new RegExp(`<div id="${id}"[^>]*?></div>`),
        `<div id="${id}">${renderToString(
          <PostPhotoGallery slides={slides} source={source} description={description} objectFit={objectFit} />,
        )}</div>`,
      );
    }, html);
  }

  return html;
};
