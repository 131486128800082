import { TThunkAction } from '../../../types/redux';
import {
  fetchSubscribeToComments,
  TSubscribeToCommentsResponse,
  ISubscribeToCommentsRequest,
} from '../../../services/postComments/subscribeToComments';
import { throwError } from '../../../utils';
import { PostTypeAsContentTypeModel } from '../../../constants';
import { EType } from '../../../repositories/journal/entities/journal/JournalAttributesSchema';
import { setPostAttribute } from '../../post';

export interface ISubscribeToComments {
  postType: EType;
  email: string;
  postId: ISubscribeToCommentsRequest['object_pk'];
  isSubscriptionToNewsChecked: ISubscribeToCommentsRequest['subscribe_news'];
}

export const subscribeToComments = ({
  postType,
  email,
  postId,
  isSubscriptionToNewsChecked,
}: ISubscribeToComments): TThunkAction<Promise<boolean>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;
    const { userNew: user } = getState();

    try {
      const { statusCode }: TSubscribeToCommentsResponse = await fetchSubscribeToComments({
        httpApi,
        parameters: {
          ...(user.isAuthenticated && user.userId ? { user_id: user.userId } : {}),
          email,
          content_type_model: PostTypeAsContentTypeModel[postType],
          object_pk: postId,
          subscribe_news: isSubscriptionToNewsChecked,
        },
      });

      if (statusCode !== 201) {
        throwError({
          message: 'Не удалось подписаться на комменты',
          statusCode,
          domain: 'actions.subscribeToComments',
        });

        return false;
      }

      dispatch(setPostAttribute({ key: 'isSubscribedToComments', value: true }));

      return true;
    } catch (e) {
      logger.error(e, {
        domain: 'actions.subscribeToComments',
      });

      return false;
    }
  };
};
