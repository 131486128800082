import { applyMiddleware, combineReducers, compose, createStore as _createStore } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { oldReducers } from '../../../app/redux/reducers';
import { IApplicationContext } from '../../types/applicationContext';
import { IApplicationState, IReduxStore, TReduxActions, TReduxInitialState } from '../../types/redux';
import { requestMiddleware } from '../../../app/helpers/request_helper';
import {
  userReducer,
  menuReducer,
  tagsReducer,
  mainNewsReducer,
  geoReducer,
  mainArticlesReducer,
  settingsReducer,
  similarArticlesReducer,
  recommendedOffersReducer,
  authorArticlesReducer,
  pagesMetaReducer,
  articleListReducer,
  slidersReducer,
  newsListReducer,
  blogListReducer,
  questionListReducer,
  searchingResultsReducer,
  postContentRecommendedOffersReducer,
  postContentBeforeAfterGalleriesReducer,
  postContentPhotoGalleriesReducer,
  postContentBlockquotesReducer,
  documentGroupListReducer,
  discussionsReducer,
  postReducer,
  postCardReducer,
  seoUrlsReducer,
  postPollReducer,
} from '../../reducers';

interface IThunkExt {
  dispatch: ThunkDispatch<IApplicationState, IApplicationContext, TReduxActions>;
}

let storeReminder: IReduxStore;

export function createReduxStore(
  context: IApplicationContext,
  debugEnabled?: boolean,
  initialState?: TReduxInitialState,
) {
  const reducers = combineReducers<IApplicationState, TReduxActions>({
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    ...(oldReducers as any),
    menu: menuReducer,
    userNew: userReducer,
    tagsNew: tagsReducer,
    mainNews: mainNewsReducer,
    geo: geoReducer,
    mainArticles: mainArticlesReducer,
    settings: settingsReducer,
    similarArticles: similarArticlesReducer,
    recommendedOffers: recommendedOffersReducer,
    authorArticles: authorArticlesReducer,
    pagesMeta: pagesMetaReducer,
    articleList: articleListReducer,
    sliders: slidersReducer,
    newsList: newsListReducer,
    blogList: blogListReducer,
    questionList: questionListReducer,
    searchingResults: searchingResultsReducer,
    postContentRecommendedOffers: postContentRecommendedOffersReducer,
    postContentBeforeAfterGalleries: postContentBeforeAfterGalleriesReducer,
    postContentPhotoGalleries: postContentPhotoGalleriesReducer,
    postContentBlockquotes: postContentBlockquotesReducer,
    documentGroupList: documentGroupListReducer,
    discussions: discussionsReducer,
    post: postReducer,
    postPoll: postPollReducer,
    postCardNew: postCardReducer,
    seoUrlsNew: seoUrlsReducer,
  });

  const composeEnhancers = debugEnabled ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

  const storeEnhancers = composeEnhancers(
    applyMiddleware(thunk.withExtraArgument(context), requestMiddleware(context)),
  );

  const createStore = (): IReduxStore =>
    _createStore<IApplicationState, TReduxActions, IThunkExt, {}>(
      reducers,
      initialState as IApplicationState,
      storeEnhancers,
    );

  if (process.env.NODE_ENV === 'development' && debugEnabled) {
    if (!storeReminder) {
      storeReminder = createStore();
    }

    storeReminder.replaceReducer(reducers);

    return storeReminder;
  }

  return createStore();
}
