/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { ca } from '@cian/analytics';
import { TDeviceType, useDeviceType } from '@cian/ui-kit';
import { ThunkDispatch } from 'redux-thunk';

import ClientAddFormComponent from '../../components/client_add_form/client_add_form_async';
import { IBreadItem } from '../../types/bread_crumb';
import { setBreadCrumb } from '../../reducers/modules/bread_crumbs/bread_crumbs';
import { BreadCrumbs } from '../bread_crumbs/bread_crumbs';
import { cleanupStorage, submitAddBlog, submitEditBlog, uploadImage } from '../../reducers/modules/add_blog/add_blog';
import { fetchUser } from '../../reducers/modules/current_user';
import { getSeoSuccess } from '../../reducers/modules/seo/seo';
import { getRegions } from '../../reducers/modules/regions/regions';
import { IRegions } from '../../types/regions';
import { getTags } from '../../reducers/modules/tags/tags';
import { ITags } from '../../types/tags';
import { IClientAddForm, ICategory } from '../../types/client_add_form';
import { IPostCard } from '../../types/post_card';
import { IPostItem } from '../../types/posts';
import { IUser } from '../../types/user';
import { getPostCard, notEditCard, editCard } from '../../reducers/modules/post_card/post_card';
import * as Analytics from '../../../app/helpers/analytics_helper';
import { combineDataLayerObject } from '../../../app/helpers/combine_data_layer_object';
import { getBreadCrumbsNames } from '../../../app/helpers/breadcrumbs_helper';
import { IApplicationState, IThunkContext, TReduxActions } from '../../types/redux';
import { ISeoItem } from '../../types/seo_data';
import { IAnalytics } from '../../types/analytics';
import { IConfig } from '../../types/config';
import clientAddFormStyles from '../../components/client_add_form/client_add_form.css';
import { prepareQSParam } from '../../utils';
import s from './add_blog.css';

interface IProps {
  regions: IRegions;
  tags: ITags;
  addBlog: IClientAddForm;
  postCard: IPostCard;
  user: IUser;
  deviceType: TDeviceType;
  analytics: IAnalytics;
  config: IConfig;
}

interface IDispatchProps {
  setBreadCrumb(path: IBreadItem[]): void;
  getRegions(scheme: string, host: string): void;
  getTags(): void;
  getSeoSuccess(seo: ISeoItem): void;
  getPostCard(pathname: string, params?: { name: string; value: string | number }[]): void;
  cleanupStorage(): void;
  fetchUser(): void;
  notEditCard(): void;
  editCard(): void;
  uploadImage(image: string): void;
  submitAddBlog(
    content: string,
    status: string,
    is_subscribed_to_comments: boolean,
    category: string,
    regions_id: number[],
    tags: string,
    title: string,
    image: string,
    userEmail: string,
    userName: string,
  ): void;
  submitEditBlog(
    id: number,
    content: string,
    status: string,
    is_subscribed_to_comments: boolean,
    category: string,
    regions_id: number[],
    tags: string,
    title: string,
    image: string,
    image_clear: boolean,
  ): void;
}

const AddBlogContainer: React.FC<IProps & IDispatchProps & RouteComponentProps> = props => {
  const [editingItem, setEditingItem] = useState<IPostItem>();
  const deviceType = useDeviceType();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const qsPostSlugAndId = prepareQSParam(searchParams.get('slug-id'));

  const categories: ICategory[] = [
    { id: '', value: '', label: 'Не выбрана' },
    { id: 'specialist', value: 'specialist', label: 'Специалист-специалисту' },
    { id: 'market_trend', value: 'market_trend', label: 'Тенденции рынка' },
  ];

  useEffect(() => {
    const { config, deviceType } = props;

    const crumbs: IBreadItem[] = [{ path: '/magazine' }, { path: '/blogs' }, { path: '/add-blog' }];

    if (typeof window !== 'undefined') {
      ca('pageviewSite', combineDataLayerObject(props.analytics, getBreadCrumbsNames(crumbs), deviceType));
    }

    props.setBreadCrumb(crumbs);
    props.getRegions(config.regionsApiSchemeNode, config.regionsApiHostNode);
    props.getTags();
    props.getSeoSuccess({
      seoTitle: 'Новая запись в блоге',
      seoUrl: 'https://cian.ru/add-blogs/',
      seoDescription: `ЦИАН - авторские колонки, блоги специалистов рынка недвижимости, блоги риэлторов. Обсуждения, комментарии.`,
      seoKeywords: 'авторские колонки, блоги специалистов рынка недвижимости, блоги риэлторов',
    });

    if (qsPostSlugAndId) {
      props.editCard();
      props.getPostCard(`/v1/get-blog`, [
        {
          name: 'id',
          value: qsPostSlugAndId,
        },
        {
          name: 'user_id',
          value: props.user.userId,
        },
      ]);
    } else if (props.addBlog.isEdit) {
      setEditingItem(props.postCard.item);
    }
    props.cleanupStorage();

    return () => {
      if (props.addBlog.isEdit) {
        props.notEditCard();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.postCard.item.id) {
      setEditingItem(props.postCard.item);
    }
  }, [props.postCard.item]);

  const browserHistory = useHistory();
  useEffect(() => {
    if (props.addBlog.item && props.addBlog.item.id) {
      props.fetchUser();
      browserHistory.push(`/blogs-${props.addBlog.item.attributes.slug}-${props.addBlog.item.id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.addBlog.item]);

  const onSubmitAddBlog = (
    content: string,
    status: string,
    isSubscribedToComments: boolean,
    category: string,
    regionsId: number[],
    tags: string,
    title: string,
    imageData: string,
    userEmail: string,
    userName: string,
  ) => {
    if (status === 'draft') {
      Analytics.addPost('save_post');
    } else {
      Analytics.addPost('add_post');
    }

    if (isSubscribedToComments) {
      Analytics.subscribeComments('new_comments', 'Magazine_subscription');
    }

    props.submitAddBlog(
      content,
      status,
      isSubscribedToComments,
      category,
      regionsId,
      tags,
      title,
      imageData,
      userEmail,
      userName,
    );
    props.fetchUser();
  };

  const onSubmitEditBlog = (
    id: number,
    content: string,
    status: string,
    isSubscribedToComments: boolean,
    category: string,
    regionsId: number[],
    tags: string,
    title: string,
    imageData: string,
    imageClear: boolean,
  ) => {
    props.submitEditBlog(
      id,
      content,
      status,
      isSubscribedToComments,
      category,
      regionsId,
      tags,
      title,
      imageData,
      imageClear,
    );
  };

  return (
    <div className={s['add-blog__wrapper']}>
      <div className={s['breadcrumbs-wrap']}>
        <BreadCrumbs />
      </div>
      <ClientAddFormComponent
        user={props.user}
        item={props.addBlog.item}
        heading={props.addBlog.isEdit ? 'изменить запись в блоге' : 'новая запись в блоге'}
        entityName="записи"
        styles={clientAddFormStyles}
        showLawBlock={true}
        showDescribeBlock={true}
        showImage={true}
        tags={props.tags.items.map(tag => tag.attributes.name)}
        showSubscribe={true}
        onSubmitAddForm={onSubmitAddBlog}
        regions={props.regions.items}
        error={props.addBlog.error}
        fetching={props.addBlog.fetching}
        categories={categories}
        editingItem={editingItem}
        isEdit={props.addBlog.isEdit}
        onSubmitEditForm={onSubmitEditBlog}
        type="blog"
        deviceType={deviceType}
        uploadImage={(img: string) => props.uploadImage(img)}
      />
      <div className={s['breadcrumbs-wrap-mobile']}>
        <BreadCrumbs mobile={true} />
      </div>
    </div>
  );
};

function mapStateToProps(state: IApplicationState): IProps {
  const { regions, tags, addBlog, postCard, user, deviceType, analytics, config } = state;

  return {
    regions,
    tags,
    addBlog,
    postCard,
    user,
    deviceType,
    analytics,
    config,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<IApplicationState, IThunkContext, TReduxActions>): IDispatchProps {
  return {
    setBreadCrumb: (path: IBreadItem[]) => {
      dispatch(setBreadCrumb(path));
    },
    getRegions: (scheme: string, host: string) => {
      dispatch(getRegions(scheme, host));
    },
    getTags: () => {
      dispatch(getTags());
    },
    getSeoSuccess: (seo: ISeoItem) => {
      dispatch(getSeoSuccess(seo));
    },
    getPostCard: (pathname: string, params?: { name: string; value: string | number }[]) => {
      dispatch(getPostCard(pathname, params));
    },
    cleanupStorage: () => {
      dispatch(cleanupStorage());
    },
    fetchUser: () => {
      dispatch(fetchUser());
    },
    notEditCard: () => {
      dispatch(notEditCard());
    },
    editCard: () => {
      dispatch(editCard());
    },
    uploadImage: (image: string) => dispatch(uploadImage(image)),
    submitAddBlog: (
      content: string,
      status: string,
      is_subscribed_to_comments: boolean,
      category: string,
      regions_id: number[],
      tags: string,
      title: string,
      image: string,
      userEmail: string,
      userName: string,
    ) => {
      dispatch(
        submitAddBlog(
          content,
          status,
          is_subscribed_to_comments,
          category,
          regions_id,
          tags,
          title,
          image,
          userEmail,
          userName,
        ),
      );
    },
    submitEditBlog: (
      id: number,
      content: string,
      status: string,
      is_subscribed_to_comments: boolean,
      category: string,
      regions_id: number[],
      tags: string,
      title: string,
      image: string,
      image_clear: boolean,
    ) => {
      dispatch(
        submitEditBlog(
          id,
          content,
          status,
          is_subscribed_to_comments,
          category,
          regions_id,
          tags,
          title,
          image,
          image_clear,
        ),
      );
    },
  };
}

export const AddBlog = connect(mapStateToProps, mapDispatchToProps)(AddBlogContainer);
