import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { hydrate } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PostPhotoGallery } from '../../components/PostPhotoGallery';
import { parsePhotoGalleriesNew } from './parsePhotoGalleriesNew';
import { selectPostContentPhotoGalleries } from '../../selectors/postContentPhotoGalleries';
import { setPostContentPhotoGallery, resetPostContentPhotoGallery } from '../../actions/postContentPhotoGallery';
import { TPostContentPhotoGalleryState } from '../../types/postContentPhotoGalleries';

interface IUseContentParams {
  canParse: boolean;
  canRender: boolean;
  postContent: string;
  setPostContent: Dispatch<SetStateAction<string>>;
  done(): void;
}

/**
 * Рендерит и управляет Фотогалереями
 */
export const useContentPhotoGalleriesNew = ({
  canParse,
  canRender,
  postContent,
  setPostContent,
  done,
}: IUseContentParams) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [prevPathname, setPrevPathname] = useState(pathname);
  const [isParsed, setIsParsed] = useState(false);
  const galleries = useSelector(selectPostContentPhotoGalleries);

  /** Сбрасывает стэйт при клиентском переходе с поста на пост */
  useEffect(() => {
    if (pathname !== prevPathname) {
      dispatch(resetPostContentPhotoGallery());
      setPrevPathname(pathname);
      setIsParsed(false);
    }
  }, [dispatch, pathname, prevPathname]);

  /** Рендерит галереи */
  useEffect(() => {
    if (!galleries.length || !canRender) {
      return;
    }

    galleries.forEach(({ id, slides, source, description, objectFit }: TPostContentPhotoGalleryState) => {
      const root = document.getElementById(id);

      if (root && slides?.length) {
        hydrate(
          <PostPhotoGallery slides={slides} source={source} description={description} objectFit={objectFit} />,
          root,
        );
      }
    });
  }, [canRender, galleries]);

  /** Парсит параметры галерей */
  useEffect(() => {
    if (!canParse || isParsed) {
      return;
    }

    if (galleries.length) {
      setIsParsed(true);
      done();

      return;
    }

    const { items, html } = parsePhotoGalleriesNew(postContent, ({ id }) => `<div id="${id}"></div>`);

    if (items.length) {
      setPostContent(html);

      items.map(({ id, slides, description, source, objectFit, position }) =>
        dispatch(
          setPostContentPhotoGallery({
            id,
            slides,
            description,
            source,
            objectFit,
            position,
          }),
        ),
      );
    }

    setIsParsed(true);
    done();
  }, [canParse, dispatch, done, galleries.length, isParsed, postContent, setPostContent]);

  /** При размонтировании сбрасывает все галереи */
  useEffect(() => {
    return () => {
      dispatch(resetPostContentPhotoGallery());
    };
  }, [dispatch]);
};
