import React from 'react';

import { EStatus } from '../../repositories/journal/entities/journal/JournalAttributesSchema';
import * as s from './PostStatusLabel.css';

interface IPostStatusLabel {
  type: EStatus;
  label: string;
}

/**
 * Лейбл статус поста
 */
export const PostStatusLabel = ({ type, label }: IPostStatusLabel) => (
  <div className={`${s['wrapper']} ${s[type]}`}>{label}</div>
);
